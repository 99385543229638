import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocalStorage } from '../../hooks/localstorage';
import {
    postMember,
    postPromoCode,
    postValidateOrder,
    postValidateTimeslot,
    postTabOrder,
    getTabOrders
} from '../../services/api/basket';

import {Item, ModifierShort, Timeslot, TSite} from '../site/site-context';
import useSiteContext from '../site/useSiteContext';
import { BasketContext, BasketContextData, Order, TabOrder, OrderBasket, Price, TBasket } from './basket-context';
import {useLocation} from "react-router-dom";
import {iLog} from "../../index";
import mixpanel from "mixpanel-browser";
import useBasketContext from "./useBasketContext";
import {toastNormal} from "../../hooks/toastNormal";
import {t} from "ttag";

const BasketContextProvider = ({ children }: { children: JSX.Element }) => {
    const [basket, setBasket] = useState<TBasket | null | undefined>(undefined);
    const [basketItems, setBasketItems] = useState<Item[] | null>(null);

    const [tableNumber, setTableNumber] = useState<string | null>(null);
    const [customerEmail, setCustomerEmail] = useState<string | null>(null);
    const [specialNotes, setSpecialNotes] = useState<string | null | undefined>(undefined);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [promoError, setPromoError] = useState('');


    const [timeslot, setTimeslot] = useState<Timeslot | null>(null);

    const [validatedBasket, setValidatedBasket] = useState<TBasket | null | undefined>(undefined);
    const [validatedSite, setValidatedSite] = useState<TSite | null | undefined>(undefined);

    const [price, setPrice] = useState<Price>({
        deliveryFee: 0,
        serviceFee: 0,
        subtotal: 0,
        total: 0,
    });

    const siteContext = useSiteContext();
    const basketContext = useBasketContext();

    const [storedBasket, setStoredBasket] = useLocalStorage('basket', null);

    const [check, setCheck] = useState<string>('');

    const navigate = useNavigate();

    const location = useLocation();

    // Clear basket if on the homepage..
    useEffect(() => {
        if (location.pathname === '/') {
            clearBasket();
        }
    }, []);


    // Load basket from storage if same site.
    useEffect(() => {

        // console.debug('Shall we load basket from storage?', [
        //     storedBasket,
        //     basketItems === null,
        //     siteContext.site,
        //     ((storedBasket || basketItems === null) && siteContext.site)
        // ]);

        if ((storedBasket || basketItems === null) && siteContext.site) {
            if (siteContext.site?.uid === storedBasket?.siteId) {

                // console.debug('Stored basket loaded. No site id mismatch. Updating local basket with stored items.',
                //     storedBasket?.basketItems,
                //     storedBasket?.timeslot
                // );

                setBasketItems(storedBasket?.basketItems ?? []);
                setTimeslot(storedBasket.timeslot);
            } else {
                //console.debug('Clearing basket from other site.', siteContext.site?.uid, storedBasket?.siteId);
                setBasketItems([]);
            }
        }
    }, [siteContext.site]);

    // attempt to set local basket to saved to allow checkout reload
    useEffect(() => {
        // console.debug('Shall we attempt to set local basket to saved to allow checkout reload?',[
        //     storedBasket !== undefined,
        //     basket === undefined
        // ]);

        if (storedBasket !== undefined && basket === undefined) {
            // console.debug(
            //     'Setting local basket to stored as stored found and local not set.',
            //     storedBasket?.validated_basket,
            //     basket,
            // );
            setBasket(storedBasket?.validated_basket ?? null);
            setValidatedBasket(storedBasket?.validated_basket ?? null);
            setValidatedSite(storedBasket?.validated_basket_site ?? null);
            setTableNumber(storedBasket?.validated_table_number ?? null);
        }
    }, [storedBasket]);

    // Sync local basket to storage.
    useEffect(() => {

        // Checks basketItems have actually loaded in before syncing to storedBasket.
        if (basketItems !== null || timeslot !== null) {
            iLog('Basket loaded, syncing stored basket items and timeslot.', [basketItems, timeslot]);
            setStoredBasket({
                ...(storedBasket ?? {}),
                basketItems,
                siteId: siteContext.site?.uid ?? '',
                timeslot: timeslot
            });
        }
    }, [basketItems, timeslot]);

    // Calculate totals
    useEffect(() => {
        let [subtotal, deliveryFee, serviceFee, total] = [0, 0, 0, 0];

        basketItems?.forEach((item) => {
            subtotal += (item.modifiersTotal ?? 0) * (item.quantity ?? 1); //(item.modifiers?.reduce((total, mod) => total + (mod.price * (mod.quantity ?? 0)), 0) ?? 0);
            subtotal += item.price * (item.quantity ?? 1);
        });

        iLog('basket items totals calc', [subtotal, deliveryFee, serviceFee, total]);

        total = subtotal + deliveryFee + serviceFee;
        setPrice({
            deliveryFee,
            serviceFee,
            subtotal,
            total,
        });

        iLog('basket items totals calc', basketItems);

    }, [basketItems]);

    const validateBasket = async (removePromo: boolean = false) => {
        if (!siteContext.site?.uid) {
            console.error('No valid site set on basket.');
            return;
        }

        setLoading(true);

        // clear previously validated info
        setStoredBasket((currentBasket: TBasket | null | undefined) => ({
            ...currentBasket,
            validated_basket: null,
            validated_basket_site: null,
            validated_table_number: null,
        }));

        let orderBasket: OrderBasket[] =
            basketItems?.map((item) => {
                return {
                    item_id: item.uid,
                    quantity: item.quantity ?? 1,
                    modifiers:
                        item.modifiers?.map((a) => {
                            // if (a.quantity === -1) {
                            //     return { modifier_id: '-' + a.uid };
                            // } else {
                                return { modifier_id: a.uid, quantity: a.quantity ?? 1 };
                            //}
                        }) ?? [],
                };
            }) ?? [];

        let order: Order = {
            uid: siteContext.site!.uid,
            channel: siteContext.channel,
            time: timeslot?.id,
            asap: timeslot && timeslot.is_asap ? true : false,
            basket: JSON.stringify(orderBasket),
            subtotal: price.total,
            flow: siteContext.flow,
            special_notes: specialNotes,
            remove_promo: removePromo,
            address: localStorage.getItem('address')
        };

        iLog('order', order);
        await postValidateOrder(order)
            .then((data: any) => {
                iLog('Validating...', [data.basket_id, data]);
                mixpanel.track('validated basket')
                updateValidatedBasket(data);
                setPromoError('');
                navigate('/checkout');
                // TODO: SAVE this data, required for the confirmation page - ALSO USE BASKET RETURNED HERE FOR BASKET on confirmation
            })
            .finally(() => setLoading(false));
    };

    const validateTimeslot = async () => {
        if (!siteContext.site?.uid) {
            console.error('No valid site set on basket.');
            return;
        }

        setLoading(true);

        await postValidateTimeslot(
                validatedBasket?.basket_id,
                timeslot?.id,
                !!(timeslot && timeslot.is_asap),
                siteContext.flow,
            )
            .then((data: any) => {
                iLog('Validating timeslot...', [data.basket_id, data]);
                setBasket((prev: any) => data);
                setStoredBasket((currentBasket: TBasket | null | undefined) => ({
                    ...currentBasket,
                    validated_basket: { ...storedBasket?.validated_basket,  ready_time: data.ready_time },
                }));

            })
            .finally(() => setLoading(false));
    };

    const updateValidatedBasket = (data: any) => {
        // if (!data.basket_id) {
        //     throw new Error('No basket id retrieved on basket validation.');
        // }

        // removed data merging as it was not triggering useEffect properly in ordercontext

        iLog('updateValidatedBasket', [data, validatedBasket]);

        let ready_time = data.ready_time;

        let new_basket = { ...data, channel: siteContext.channel };

        if ( ready_time ) {
            new_basket = { ...data, channel: siteContext.channel, ready_time: ready_time }
        }

        setBasket((prev: any) => data);
        setStoredBasket((currentBasket: TBasket | null | undefined) => ({
            ...currentBasket,
            validated_basket: new_basket,
            validated_basket_site: siteContext.site,
            validated_table_number: tableNumber,
        }));

        if (data.timeslots) {
            siteContext.setTimeslots(data.timeslots);
        }
    };

    useEffect(() => {
        //console.debug('Stored basket changed...', storedBasket);
        setValidatedBasket(storedBasket?.validated_basket ?? null);
        setValidatedSite(storedBasket?.validated_basket_site ?? null);
    }, [storedBasket]);

    const addItem = (item: Item, quantity: number, edit: boolean) => {
        iLog('add item', item);

        if ( quantity < 1 ) {
            iLog('BasketContextProvider.addItem: quantity is 0', null);
            return removeItem(item);
        }

        let i = cloneDeep(item);

        iLog('cloned deep', i);

        // extract selected modifiers from their groups
        let foo: ModifierShort[] = [];
        i.modifier_groups.forEach((group) => {
            return group.modifiers.forEach((mod) => {
                if (mod.quantity > 0) {
                    foo.push({
                        uid: mod.uid,
                        name: mod.name,
                        quantity: mod.quantity,
                        group_uid: mod.group_uid ?? ''
                    });
                }

                // unselecting default mod
                if ( mod.is_default && mod.quantity === 0 ) {
                    foo.push({
                        uid: mod.uid,
                        name: mod.name,
                        quantity: -1,
                        group_uid: mod.group_uid ?? ''
                    });
                }
            });
        })
        i.modifiers = foo;

        if ( !i.basketIndex ) {
            let str = makeid(5);
            iLog('no index, making one..', str)
            i.basketIndex = str;
        }

        iLog('add or edit?', [edit, i]);

        if ( edit ) {
            setBasketItems(editItemInBasketItems(basketItems ?? [], i, quantity));
        } else {
            setBasketItems(addItemToBasketItems(basketItems ?? [], i, quantity));
        }
    };

    function makeid(length: number) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const editItemInBasketItems = (currentItems: Item[], newItem: Item, newItemQuantity: number = 1) => {
        let newItems = [...currentItems];

        iLog('edit in basket', [newItem.basketIndex, newItems]);

        if ( newItem.basketIndex ) {

            newItems.forEach(function(item, i) {
                if (item.basketIndex === newItem.basketIndex) {
                    newItems[i] = newItem;
                    newItems[i].quantity = newItemQuantity;
                }
            });


            iLog('editing items', newItems);
        }

        return newItems;
    };

    const addItemToBasketItems = (
        currentItems: Item[],
        newItem: Item,
        newItemQuantity: number = 1,
    ): Item[] => {
        let exactMatchAdded = false;
        let newBasketItems = [...currentItems];

        iLog('add item in basket', newItem);

        newBasketItems = currentItems.map((cItem: Item) => {
            if (exactMatchAdded) {
                return cItem;
            }

            if (cItem.uid !== newItem.uid) {
                return cItem;
            }

            // Not possible for mods to match
            if (cItem.modifiers?.length !== newItem.modifiers?.length) {
                return cItem;
            }

            // every modifier in this item matches the new item
            let exactMatchFound = cItem.modifiers?.every((cMod) => {
                return newItem.modifiers?.some((newMod) => {
                    if (cMod.uid === newMod.uid && cMod.quantity === newMod.quantity) {
                        return true;
                    }
                });
            });

            if (exactMatchFound) {
                cItem.quantity = (cItem.quantity ?? 1) + newItemQuantity;
                exactMatchAdded = true;
            }

            return cItem;
        });

        if (!exactMatchAdded) {
            newItem.quantity = (newItem.quantity ?? 0) + newItemQuantity;
            return [...newBasketItems, newItem];
        } else {
            return newBasketItems;
        }
    };

    const hasExactItemMatch = (currentItems: Item[], newItem: Item): boolean => {
        if (!currentItems.length) {
            return false;
        }

        return currentItems.some((cItem: Item) => {
            if (cItem.uid !== newItem.uid) {
                return false;
            }

            // Not possible for mods to match
            if (cItem.modifiers?.length !== newItem.modifiers?.length) {
                return false;
            }

            // every modifier in this item matches the new item
            return cItem.modifiers?.every((cMod) => {
                return newItem.modifiers?.some((newMod) => {
                    if (cMod.uid === newMod.uid) {
                        //&& cMod.quantity === newMod.quantity
                        return true;
                    }
                });
            });
        });
    };

    const removeItem = (item: Item) => {
        iLog(`Removing item from basket.`, item);
        let newBasket = basketItems?.filter((basketItem) => {
            let foundExactSameItem = false;

            if (basketItem.uid === item.uid && basketItem.modifiers?.length === item.modifiers?.length) {
                /* same length of modifiers - so now check if modifiers are the same  */
                let sameModifierCount = 0;
                basketItem.modifiers?.forEach((basketItemMod) => {
                    item.modifiers?.forEach((itemMod) => {
                        if (itemMod.uid === basketItemMod.uid && itemMod.quantity === basketItemMod.quantity) {
                            sameModifierCount++;
                        }
                    });
                });

                if (sameModifierCount === item.modifiers?.length) {
                    return false;
                }
            } else if (basketItem.uid === item.uid) {
                // else just remove any with same id anyway
            }

            return true;
        });

        setBasketItems(newBasket ?? []);
    };

    const clearBasket = () => {
        setBasketItems([]);
        setValidatedBasket(null);
        setValidatedSite(null);
        setTimeslot(null);
        siteContext.setFlow('');
        siteContext.setTimeslots([]);
        setStoredBasket(null);
    };

    const validatePromoCode = async (code: string, email: string | null) => {

        setPromoError('');

        await postPromoCode(
            validatedBasket?.basket_id ?? '',
            code,
            validatedBasket?.payment_intent?.client_secret ?? '',
            siteContext.flow ?? 'noflow',
            email,
        ).then((data) => {

            // add the previously stored ready_time
            data.ready_time = validatedBasket?.ready_time;

            iLog('validatePromoCode', data);

            updateValidatedBasket(data);

        }).catch(err => {

            iLog('validatePromoCode err', err);
            setPromoError(err.message);
        });
    };


    const validateMemberId = async (email: string, member: string) => {

        await postMember(
            validatedBasket?.basket_id ?? '',
            email,
            member,
            siteContext.flow ?? 'noflow'
        )
            .then((data) => {
                iLog('validatePromoCode', data);
                return data;
            }).catch(err => {
                iLog('validatePromoCode err', err);
                throw err;
            });

    }

    const addToTab = async () => {
        if (!siteContext.site?.uid) {
            console.error('No valid site set on basket.');
            return;
        }

        if (!tableNumber) {
            console.error('No valid table number set on basket.', tableNumber);
            return;
        }

        setLoading(true);

        // clear previously validated info


        let orderBasket: OrderBasket[] =
            basketItems?.map((item) => {
                return {
                    item_id: item.uid,
                    quantity: item.quantity ?? 1,
                    modifiers:
                        item.modifiers?.map((a) => {
                            // if (a.quantity === -1) {
                            //     return { modifier_id: '-' + a.uid };
                            // } else {
                            return { modifier_id: a.uid, quantity: a.quantity ?? 1 };
                            //}
                        }) ?? [],
                };
            }) ?? [];

        let order: TabOrder = {
            basket: orderBasket,
            table_no: tableNumber,
            site: siteContext.site.uid,
        };

        iLog('tab order', order);

        await postTabOrder(order, siteContext.flow ?? 'noflow')
            .then((data: any) => {
                iLog('Validating tab order...', [data.basket_id, data]);
                mixpanel.track('validated tab basket')
                //updateValidatedBasket(data);
                basketContext.clearBasket();


                navigate(`/at-table/${siteContext.site?.handle}/table/${data.table}`);
            })
            .finally(() => {
                setLoading(false);
                toastNormal(t`Thank you! Your order has been placed.`);
            });
    };

    const getTab = async (site: string, table: string) => {

        if ( site.length === 0 ) {
            console.error('No valid site set on basket.');
            return;
        }

        //let table_no = tableNumber ?? localStorage.getItem('table');

        if ( table.length === 0 ) {
            console.error('No valid table number set on basket.', table);
            return;
        }

        return getTabOrders(site, table, siteContext.flow ?? 'noflow')
            .then((data: any) => {
                mixpanel.track('getting tab')
                updateValidatedBasket(data);
                setCheck(data.check)
                // navigate('/checkout');
                return data;
            });
            //.finally(() => setLoading(false));
    };

    let basketContextValue: BasketContextData = {
        basket,
        setBasket,

        basketItems: basketItems ?? [],
        setBasketItems,

        loading,
        setLoading,

        validateBasket,
        validateTimeslot,
        clearBasket,
        addToTab,
        getTab,

        validatePromoCode,
        validateMemberId,

        validatedBasket,
        validatedSite,

        addItem,
        removeItem,

        timeslot,
        setTimeslot,

        price,
        setPrice,

        tableNumber,
        setTableNumber,

        customerEmail,
        setCustomerEmail,

        error,
        setError,

        check,
        setCheck,

        promoError,
        setPromoError,

        specialNotes,
        setSpecialNotes

    };
    return <BasketContext.Provider value={basketContextValue}>{children}</BasketContext.Provider>;
};

export default BasketContextProvider;
