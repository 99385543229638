import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../contexts/basket/useBasketContext';
import useSiteContext from '../../../contexts/site/useSiteContext';
import useOperatorContext from "../../../contexts/operator/useOperatorContext";
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import ScrollToTop from '../../../hooks/scrollToTop';
import {CTA} from "../../general/Button";
import useOrderContext from "../../../contexts/order/useOrderContext";
import {Item, TSite} from "../../../contexts/site/site-context";
import {Accordion} from "react-bootstrap";
import BasketItem from "../Menu/components/basket/BasketItem";
import {rgba} from "polished";
import InfoCard from "../Menu/components/basket/InfoCard";
import SplitPaymentModal from "./SplitPaymentModal";
import {money} from "../../../services/format";
import basket from "../Menu/components/basket/Basket";


const PreCheckoutPage = () => {

    const navigate = useNavigate();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const orderContext = useOrderContext();

    const [bItems, setBItems] = useState<Item[]>([]);
    const [bItemsCount, setBItemsCount] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState<Array<any>>([]);

    const [splitShow, setSplitShow] = useState(false);

    useEffect(() => {
        if (! basketContext.validatedBasket?.basket ) {
            navigate('/');
        }
    }, []);

    useEffect(() => {


        setBItems(basketContext.validatedBasket?.basket ?? []);

        let count = 0;
        bItems.forEach((item: Item) => {
            count += item.quantity ?? 0;
        });

        setBItemsCount('('+ count + ' item' + (Number(count) > 1 ? 's)' : ')'));

    }, [basketContext.validatedBasket]);
    const renderBasket = () => {
        return (
            <BasketWrapper>
                <StyledAccordion defaultActiveKey="-1">

                        <div className="toggle">
                            <span className="toggle__title">Your order </span>
                            <span>({bItems.reduce((total, item: Item) => total + (item.quantity ?? 0), 0)}{(bItems.reduce((total, item: Item) => total + (item.quantity ?? 0), 0) > 1 ? ' items' : ' item')})</span>
                        </div>



                        <BasketItems>
                            {bItems.map((item, index) => (
                                <BasketItem
                                    key={`basket-${index}-${item.uid}`}
                                    handleClick={() => {}}
                                    item={item}
                                    checkout={true}
                                    last={index === bItems.length - 1}
                                />
                            ))}
                        </BasketItems>



                    <div className={'lineItem'}>
                        <span>Subtotal</span>
                        <span>{ money(basketContext.validatedBasket?.subtotal ?? 0) }</span>
                    </div>


                    <div className={'lineItem'}>
                        <span>Service fee</span>
                        <span>{ money(basketContext.validatedBasket?.service_fee ?? 0) }</span>
                    </div>

                    <div className={'lineItem'}>
                        <span>Total</span>
                        <span>{ money(basketContext.validatedBasket?.total ?? 0) }</span>
                    </div>

                    {(basketContext.validatedBasket?.payments ?? 0) > 0 && (
                        <div className={'lineItem'}>
                            <span>Payments</span>
                            <span>{ money(basketContext.validatedBasket?.payments ?? 0) }</span>
                        </div>
                    )}

                    {(basketContext.validatedBasket?.payments ?? 0) > 0 && (
                        <div className={'lineItem'}>
                            <span>Due</span>
                            <span>{ money(basketContext.validatedBasket?.due ?? 0) }</span>
                        </div>
                    )}


                </StyledAccordion>
            </BasketWrapper>
        );
    };

    const tableNo = () => {
        return basketContext.tableNumber;
    }

    const siteName = () => {
        return basketContext.validatedSite?.name;
    }

    const renderChannelTitle = () => {
        return (
            <OrderInfo checkout={true} className={'basket__order-info basket__order-info--desktop'}>
                <h2 className={"basket__order-info__title"} >{t`Summary`}</h2>
                <div className={'info-card'}>
                    <InfoCard icon={'map-pin'} className="withBg mb-0">
                        <>
                            <span> Table {tableNo()} - {siteName()}</span>
                        </>
                    </InfoCard>
                </div>
                {siteContext.site?.show_instructions_at_checkout && siteContext.site?.instructions ? (
                    <Instructions>
                        <i className="feather feather-info" />
                        <span>{ siteContext.site?.instructions }</span>
                    </Instructions>
                ) : null}
            </OrderInfo>
        );
    }


    if (!siteContext || !basketContext || basketContext.validatedBasket === undefined) {
        return <PageSpinnerThree show={true} />;
    }

    if (siteContext.loading || basketContext.loading) {
        return <PageSpinnerThree show={true} />;
    }

    if (!operatorContext || !operatorContext.operator || !siteContext) {
        return <PageSpinnerThree show={true} />;
    }

    if (operatorContext.loading || siteContext.loading) {
        return <PageSpinnerThree show={true} />;
    }

    return (
        <AppLayout page="bill">
            <ScrollToTop />
            <Helmet>
                <title>{t`Checkout - Powered by orderswift`}</title>
            </Helmet>

            <StyledContainer>


                <StyledRow>
                    <StyledOrderDetails>
                        <BasketContainer checkout={true} confirmation={false}>
                            <BasketCard className="basket">
                                { renderChannelTitle() }
                                <OrderSummary>
                                { renderBasket() }
                                </OrderSummary>
                            </BasketCard>
                        </BasketContainer>
                    </StyledOrderDetails>
                    <OrderButtonContainer>

                        <SplitPaymentModal
                            show={splitShow}
                            onHide={() => setSplitShow(false)}
                            tabOrdersTotal={basketContext.validatedBasket?.due ?? 0}
                        />


                        <CTAButton
                            className={'mobile-cart-btn'}
                            onClick={async () => {
                                setSplitShow(true);
                            }}
                        >
                            <span className="basket-btn-content">
                                <span className="basket-btn-label" style={{width: '100%', top: '1px'}}>Split bill</span>
                            </span>
                        </CTAButton>
                        <CTAButton
                            className={'mobile-cart-btn'}
                            onClick={async () => {
                                orderContext.setAmount(basketContext.validatedBasket?.due ?? 0);
                                navigate('/checkout');
                            }}
                        >
                            <span className="basket-btn-content">
                                <span className="basket-btn-label" style={{width: '100%', top: '1px'}}>Checkout</span>
                            </span>
                        </CTAButton>
                    </OrderButtonContainer>
                </StyledRow>
            </StyledContainer>
        </AppLayout>
    );
};


const CTAButton = styled(CTA)`

    // CUSTOM PP - not needed
    //background-color: var(--ui_buttons_cta_basket_bg);
    height: auto;
    @media screen and (min-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
      //height: 48px !important;
    }
    
    .basket-btn-content {
        padding-left: 0px;
        display: flex;
        flex: 1 1 0%;
        gap: 12px;
        align-items: center;
        .basket-btn-label {
            flex-shrink: 0;
            white-space: nowrap;
        }
       

        @media screen and (max-width: 767px) {
            padding-left: 0;
            justify-content: space-between;
        }
        .basket-btn-divider {
            border-left: 1px solid var(--border-grey);
            margin: 0 8px;
            height: 20px;
            border-color: var(--ui_buttons_cta_basket_color);
            display: none;
        }
        .basket-btn-quantity {
            flex-grow: 1;
            display: flex;
            flex-basis: 50%;
            gap: 8px;
            align-items: center;
            span {
                display: inline-block;
                text-transform: uppercase;
                min-width: 20px;
            }
        }
        .basket-btn-text {
            flex-grow: 1;
            flex-basis: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
           
        }
        
         
    }
    
    
    
    &:not(.mobile-cart-btn) {
        height: 40px;
        
        // CUSTOM PP - not needed
        height: auto;
        line-height: 1;
        
        padding-left: 16px;
        padding-right: 16px;
        
        .basket-btn-divider {
            display: inline-block !important; 
        }   
        @media screen and (min-width: 768px) {
              .basket-btn-quantity span, .basket-btn-divider {
                   display: none !important;
              }
        }
    }

    @media screen and (max-width: 767px) {
        &:not(.mobile-cart-btn) {
            display: none !important;
        }
    }

    @media screen and (min-width: 768px) {
        &.mobile-cart-btn {
            width: 400px;
        }
    }

    @media screen and (min-width: 768px) {
        .basket-btn-quantity span, .basket-btn-divider {
            display: none !important;
        }
    }
    
    
`;

const OrderButtonContainer = styled.div`
    @media screen and (max-width: 767px) {
        display: flex;
        position: fixed;
        z-index: 999;
        padding: 16px 20px;
        background: white;
        margin: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-top: 1px solid var(--border-grey);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        justify-content: center;

        button {
            height: 48px;
            padding: 0 20px;
        }
        
        gap: 20px;
    }
`;


const OrderInfo = styled.div<{ checkout?: boolean }>`
    .info-card  {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0 0;
        
        gap: 8px;
        &__channel, &__site_name, &__timeslot {
            font-size: 16px;
            line-height: 22px;
        }
            
        &__site_name, &__timeslot {
            font-weight: 400;
            color: var(--text-grey)
        }
        &__channel {
            font-weight: 500;
        }   
        @media screen and (min-width: 993px){
            padding-bottom: 16px;
        }
    }

    img {
        margin-right: 9px;
    }
`;

const Instructions = styled.div`
    padding: 18px;
    margin-bottom: 24px;
    border-radius: 4px;
    
    background-color: var(--bg-grey);
    color: var(--text-black);
    i {
        margin-right: 12px;
    }
   
`;


const BasketContainer = styled.div<{ checkout: boolean, confirmation: boolean }>`
    margin: 0;
    
    padding: 15px;
    flex-grow: 0;
    .only-show-mobile {
        @media screen and (min-width: 993px) {
            display: none;
        }
    }
    .only-show-desktop {
        display: none;
        @media screen and (min-width: 993px) {
            display: block;
        }
    }
    ${(props) =>
    props.checkout ? `
            margin-top: 0;
            order: 2;
            padding: 0;
            ${BasketCard}{
                width: 100%;
                box-shadow: none;
            }
        ` : `
            @media screen and (max-width: 767px){
                display: none;
            }
        `
}
     ${(props) => props.confirmation ? `.customToggle {display: none} .collapse { display: block }` : ``}
`;
const BasketCard = styled.div`
    padding: 0 20px;
    width: 100%;
    z-index: 1;
    background: #ffffff;
    width: 350px;
    display: flex;
    flex-direction: column;
    //border-bottom: 1px solid var(--border-grey);
    
    @media screen and (min-width: 993px) {
        border-bottom: 0;
        padding: 0;
    }

    @media screen and (min-width: 1140px) {
        top: 84px; //(64 + 20)
    }

    hr {
        width: 100vw;
        position: relative;
        left: -16px;
        margin: 16px 0;

        @media screen and (min-width: 768px) {
            left: -24px;
            max-width: 520px;
        }
    }
    
    .basket__order-lines {
        padding: 16px 0;
        line-height: 22px;
        border-top: 1px solid var(--border-grey);
        &--end {
            border-top: 0;
            padding-top: 0;
            padding-bottom: 32px;
        }
        @media screen and (min-width: 993px) {
           &--end {
                border-top: 0;
                padding-bottom: 8px;
            }
        }
    }
    
   

    .basket__order-info {
        padding: 32px 0px;
        
        
        @media screen and (min-width: 993px) {
            padding: 0;
        }

        &--desktop {
            // display: none;
            > div.info-card {

                /*&:not(:last-child) {
                    border-bottom: 1px solid var(--border-grey);
                }*/
            }
            @media screen and (min-width: 993px) {
                display: block;
            }
        }
        &--mobile {
            border-bottom: 1px solid var(--border-grey);
        }

        &__card {
            padding: 16px;
            border-radius: 8px;
            display: flex;
            gap: 16px;
            background-color: ${(props) => rgba(props.theme.v3.ui.other.link, 0.1)};
            span {
                font-size: 16px;
                line-height: 22px;
                /*font-family: 500; not sure what this value is.. */
            }
            @media screen and (min-width: 993px) {
                padding: 0;
                background-color: transparent;
            }
        }

        &__title {
            margin-bottom: 20px;

            // CUSTOM PP - not needed
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #00572e;
            text-shadow: 1px 1px 0 #00572e, 2px 2px 0 #00572e, 3px 3px 0 #00572e, 4px 4px 0 #00572e, 5px 5px 0 #00572e, 6px 6px 0 #00572e, 7px 7px 0 #00572e, 8px 8px 0 #00572e;
            
        }
    }
    
     .basket__order-title {
        padding-top: 32px;
        padding-bottom: 16px;
        
        @media screen and (min-width: 993px){
            padding-top: 0;
            padding-bottom: 0;
        }
    }
`;

const OrderSummary = styled.div`
    padding-bottom: 20px;
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .specialNotesContainer {
        p {
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
        }
        .specialNotes {
            border-radius: 4px;
            background-color: var(--bg-grey);
            padding: 12px 16px;           
            color: var(--text-grey);
        }
    }
    .specialNotesContainer {
        padding: 16px 0;
    }
   
`;

const BasketWrapper = styled.div`
  
    .your-order-toggle {
      padding: 16px 0;
    }

    @media screen and (min-width: 768px) {
        /* max-height: calc(100vh - 330px); */
        overflow-y: visible;
        overflow-x: visible;
        scrollbar-width: none;

    }
`;

const BasketItems = styled.div`
    font-size: 16px;
    padding: 12px 0;
`;

const StyledAccordion = styled(Accordion)`
    
    .lineItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 16px;
        padding: 2px 0;
    }
    
    .toggle {
        font-size: 16px;
    }
    
    .customToggle {
        
        transition: background-color 0.15s ease-in-out;
        i { 
            transition: transform 0.15s ease-in-out;
        }
        &.show {
            i {
                transform: rotate(180deg);
            }
        }
        @media screen and (min-width: 993px) {
            // display: none;
        }
    }
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .collapse, .collapsing {
        @media screen and (min-width: 993px) {
            border-bottom: 0;
        }
    }
    > button {
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        display: flex;
        padding: 20px;
        border-top: 1px solid var(--border-grey);
        &.show {
            border-bottom: 1px solid var(--border-grey);
        }
        @media screen and (min-width: 993px) {
            padding: 20px 0;
        }
        margin: 0;
        font-weight: 600;
        align-items: center;
        color: var(--text-black);
        text-align: left;

        img {
            transition: transform 0.2s linear;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }

        &.show {
            .toggle {
            }
        }

        .toggle {
            width: 100%;
            display: flex;
            /* margin-left: 16px; */
            color: ${(props) => props.theme.shade_0};
            flex-wrap: wrap;
            flex-grow: 1;
            > span {
                margin-right: 8px;
                color: var(--brand);
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;

            &__price {
                flex-grow: 1;
                text-align: right;
            }
        }
    }
`;


const StyledAddToOrderButton = styled.div`
    border-bottom: 1px solid var(--border-grey);
    padding: 0 20px;
`;

const AddToOrderButton = styled(CTA)`
    background-color: var(--ui_buttons_cta_basket_bg);
    color: var(--ui_buttons_cta_basket_color);
    
    margin: 12px 0;
    
    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    .basket-btn-money {
        width: 90px;
        text-align: right;
    }

    
    @media screen and (min-width: 576px) {
       
        .mobile {
            //display: none;
        }

        .desktop {
            display: block;
        }


    }

    .spinner-grow {
        height: 24px;
        width: 24px;
        position: relative;
        left: 50%;
        margin-left: -12px;
    }
`

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    transition: opacity 0.1s ease-in-out;
    z-index: 999;
    align-items: center;
    justify-content: center;
    
    .pay_loading__content {
         margin-top: 112px;
         text-align: center;
         .pay_loading__logo {
            background-image: var(--logo);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0 auto; 
            max-width: 85vw;
            display: block;
            cursor: pointer;
            height: 33px;
            width: 214px;
        }
        p {
            font-size: 16px;
            line-height: 22px;
            margin-top: 12px;
            margin-bottom: 0;
            display: inline-block;
            font-family: var(--text_ui_font);
            font-weight: var(--text_ui_weight);
            text-transform: var(--text_ui_case);
            color: var(--brand);
        }
    }
    display: ${(props) => props.show ? 'flex' : 'none'};
`;

const StyledContainer = styled.div`
    max-width: 1092px;
    background-color: var(--bg-grey);
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 993px) {
        padding: 0 20px;
        background-color: var(--white);
    }
`;

const StyledRow = styled.div`
    @media screen and (min-width: 993px) {
        padding: 48px 0;
        display: flex;
        gap: 56px;
        align-items: flex-start;
    }
    @media screen and (min-width: 1140px) {
        gap: 114px;
        flex-direction: row-reverse;
    }

`;
const StyledOrderDetails = styled.div`
    align-self: flex-start;
    width: 100%;
    margin-bottom: 16px;
    @media screen and (min-width: 993px) {
        order: 2;
        max-width: 432px;
        border: 1px solid ${(props) => props.theme.color_border};
        border-color: var(--border-grey);
        border-radius: 8px;
        padding: 20px;
        position: sticky;
        top: 112px;
    }
`;
const StyledPayment = styled.div`
    width: 100%;
    // border-top: 1px solid var(--border-grey);
    @media screen and (min-width: 993px) {
        order: 1;
        padding: 0;
        border-top: 0;
    }
`;
export default PreCheckoutPage;
