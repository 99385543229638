import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    PaymentRequestButtonElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import { PaymentMethod, PaymentRequestOptions } from '@stripe/stripe-js';
import type { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {Col, Form, OverlayTrigger, Tooltip, Row} from 'react-bootstrap';
import styled, {keyframes} from 'styled-components';
import { t } from 'ttag';
import config from '../../../../config/channels';
import useBasketContext from '../../../../contexts/basket/useBasketContext';
import {CustomerFormObject} from '../../../../contexts/customer/customer-context';
import useOperatorContext from '../../../../contexts/operator/useOperatorContext';
import useOrderContext from '../../../../contexts/order/useOrderContext';
import useSiteContext from '../../../../contexts/site/useSiteContext';
import { CTA } from '../../../general/Button';
import { FormData } from './FormBuilder';
import cards from '../../../../assets/images/cards.svg';
import mastercard from '../../../../assets/images/mastercard.svg';
import visa from '../../../../assets/images/visa.svg';
import amex from '../../../../assets/images/amex.svg';
import apple_pay from '../../../../assets/images/logo-apple-pay.svg';
import google_pay    from '../../../../assets/images/logo-google-pay.svg';
import cvc from '../../../../assets/images/cvc.svg';

import TimeslotModal from '../../../timeslots/TimeslotModal';
import Radio from '../../../general/Radio';
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import {StripeElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/base";
import {iLog} from "../../../../index";
import {TError} from "../../../../contexts/order/order-context";
import mixpanel from "mixpanel-browser";
import {validatePostcode} from "../../../../services/api/site";
import {operatorName} from "../../../../config/operator";
import {TSite} from "../../../../contexts/site/site-context";
import {toastError} from "../../../../hooks/toastError";
import {money} from "../../../../services/format";
import PrivacyModal from "./PrivacyModal";
import Zillionaire from "./Zillionaire";
import TermsModal from "./TermsModal";
import RadioGroup from "../../../general/RadioGroup";
import {rgba} from "polished";
import {Theme} from "../../../../contexts/operator/operator-context";
import ChangeAddressModal from "../ChangeAddressModal";

type PaymentFormProps = {
    form: FormData;
};

interface ErrorDictionary {
    invalid_number: string,
    invalid_expiry_month: string,
    invalid_expiry_year:  string,
    invalid_cvc:  string,
    incorrect_number: string,
    incomplete_number:  string,
    incomplete_cvc:  string,
    incomplete_expiry: string,
    expired_card: string,
    incorrect_cvc:  string,
    incorrect_zip:  string,
    invalid_expiry_year_past:  string,
    card_declined:  string,
    missing: string,
    processing_error: string,
}


const PaymentForm = ({ form }: PaymentFormProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const [cardErrors, setCardErrors] = useState({
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
    });

    const [paymentType, setPaymentType] = useState<'mobile' | 'card' | 'express'>('mobile');
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const [mobilePayMethod, setMobilePayMethod] = useState<any>(null);
    const [mobilePayEnabled, setMobilePayEnabled] = useState<boolean>(false);
    const [fullyDiscountedOrder, setFullyDiscountedOrder] = useState<boolean>(false);
    const [privacyShow, setPrivacyShow] = useState(false);
    const [termsShow, setTermsShow] = useState(false);

    const [timeslotShow, setTimeslotShow] = useState(false);
    const [addressModalShow, setAddressModalShow] = useState(false);

    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const orderContext = useOrderContext();
    const siteContext = useSiteContext();

    const handleTimeslotClose = () => {
        setTimeslotShow(false);
    };

    const [card, setCard] = useState({
        card: false,
        cvc: false,
        expiry: false,
    });
    const [cardBrand, setCardBrand] = useState<string|null>(null);
    const [optInMarketing, setOptInMarketing] = useState<boolean>(operatorContext.operator?.theme.v3.default_opt_in ?? false);

    const [cardComplete, setCardComplete] = useState(false);
    const [validationErrors, setValidationErrors] = useState<TError[]>([]);

    useEffect(() => {
        let valid = card.card && card.cvc && card.expiry && form.firstName && form.lastName && form.email && form.phone;
        let valid_dine_in = card.card && card.cvc && card.expiry && form.firstName && form.lastName && form.email;


        iLog('cardComplete', [valid, valid_dine_in, card, form]);

        if (siteContext.channel === 'dine_in') {
            setCardComplete(valid_dine_in ?? false);
        } else {
            setCardComplete(valid ?? false);
        }
    }, [card, form]);


    const {
        submitForm,
        validateForm,
        isValid,
        isSubmitting,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
    } = useFormikContext();

    const formikValueRef = useRef(values);
    const formikValidRef = useRef(isValid);

    const nameInputRef = useRef(null);
    const lastNameInputRef = useRef(null);
    const emailInputRef = useRef<any>(null);
    const postcodeInputRef = useRef<any>(null);

    let pr = useRef<any>(null);
    const [prHasBeenSet, setPrHasBeenSet] = useState<boolean>(false);

    useEffect(() => {
        formikValueRef.current = values;
        //formikValidRef.current = isValid;
        //}, [values, isValid]);
    }, [values]);

    useEffect(() => {
        basketContext.setCustomerEmail(emailInputRef?.current?.value);
    }, [values])

    useEffect(() => {
        iLog('what is invalid?', [formikValueRef.current, formikValidRef.current]);
    }, [isValid]);

    useEffect(() => {
        if (basketContext.validatedBasket?.total === 0) {
            setFullyDiscountedOrder(true);
            setPaymentType('express');
        }
    }, [basketContext]);

    const editPostcode = () => {
        setAddressModalShow(true)
    };


    const errorDictionary:ErrorDictionary = {
        invalid_number: "Enter a valid card number",
        invalid_expiry_month: "Enter a valid expiration month",
        invalid_expiry_year: "Enter a valid expiration year",
        invalid_cvc: "Enter a valid security code",
        incorrect_number: "Enter a correct card number",
        incomplete_number: "Enter a complete card number",
        incomplete_cvc: "Enter a complete security code",
        incomplete_expiry: "Enter a complete expiration date",
        expired_card: "The card has expired",
        incorrect_cvc: "The card’s security code is incorrect",
        incorrect_zip: "The card’s zip code failed validation",
        invalid_expiry_year_past: "The card’s expiration year is in the past",
        card_declined: "The card was declined",
        missing: "There is no card on a customer that is being charged",
        processing_error: "An error occurred while processing the card"
    };

    useEffect(() => {

        if (stripe && basketContext.validatedBasket && prHasBeenSet) {

            let base = basketContext.validatedBasket?.total ?? 0;

            if ( basketContext.validatedBasket.channel === 'dine_in' ) {
                base = orderContext.serviceSelected
                    ? basketContext.validatedBasket?.total_service_selected ?? 0
                    : basketContext.validatedBasket?.total_service_unselected ?? 0
            }

            let val = base + orderContext.gratuityVal;

            pr.current.update({
                total: {
                    label: operatorContext.operator?.name || 'Food',
                    amount: val,
                },
            });
        }

    }, [prHasBeenSet, basketContext.validatedBasket, orderContext.gratuityVal]);

    useEffect(() => {
        if (stripe && basketContext.validatedBasket) {

            let prOptions: PaymentRequestOptions = {
                country: siteContext.site?.address_country ?? 'GB',
                currency: 'gbp',
                disableWallets: ['link'],
                total: {
                    label: operatorContext.operator?.name || 'Food',
                    amount: basketContext.validatedBasket?.total,
                },
                requestPayerName: false,
                requestPayerEmail: false,
                requestPayerPhone: false,
            };

            // 20240422: remove this..
            // if (siteContext.channel === config.delivery.name) {
            //     prOptions.requestShipping = true;
            //     prOptions.shippingOptions = [
            //         {
            //             id: 'basic',
            //             label: 'Delivery',
            //             detail: '',
            //             amount: orderContext.order?.delivery_fee ?? 0,
            //         },
            //     ];
            // }

            if (siteContext.channel === config.dineIn.name) {
                prOptions.requestPayerPhone = false;
            }

            iLog('Payment request options ', prOptions);


            pr.current = stripe.paymentRequest(prOptions);
            iLog('Payment request ', pr);

            let libraryInUse: string | null = 'your device';

            // Check the availability of the Payment Request API.
            pr.current.canMakePayment().then((result: { [x: string]: any; } | null) => {
                console.log('Can you pay by mobile?', operatorContext.operator?.allow_mobile_pay);
                console.log('Mobile payment activated?', result !== null);

                if (result) {

                    const libTextMapping: { [key: string]: string } = {
                        applePay: 'Apple Pay',
                        googlePay: 'Google Pay',
                        link: 'Link Payment',
                    };

                    for (const library in result) {
                        if (result[library]) {
                            libraryInUse = libTextMapping[library];
                            break;
                        }
                    }

                }

                if (result && operatorContext.operator?.allow_mobile_pay) {
                    console.log("Let's show our mobile payment button!");
                    setMobilePayEnabled(true);
                    setMobilePayMethod(libraryInUse);
                    setPaymentRequest(pr.current);
                    setPrHasBeenSet(true);
                    setMobilePayEnabled(true);
                } else {
                    // console.log('Mobile pay not enabled but showing the option anyway');
                    // setMobilePayEnabled(true);
                    // setPaymentRequest(pr);
                }
                
                setPaymentType('card');

            });

            pr.current.on('paymentmethod', async (ev: { payerName: string; payerEmail: any; shippingAddress: { postalCode: string | undefined; addressLine: any[]; city: string | undefined; }; payerPhone: string; paymentMethod: PaymentMethod | undefined; complete: (arg0: string) => void; }) => {
                iLog('paymentmethod event', ev);


                let dataObject = {
                    ...(formikValueRef.current as CustomerFormObject),

                    // firstName: ev.payerName?.split(' ')[0] ?? '',
                    // lastName: ev.payerName?.split(' ').slice(1, ev.payerName.length).join(' ') ?? '',
                    // cardholderName: ev.payerName,
                    // email: ev.payerEmail,

                    opt_in_marketing: optInMarketing

                } as CustomerFormObject;


                // 20240422: we are no longer validating the postcode against the device address
                /*if ( siteContext.channel === config.delivery.name
                    && ev.shippingAddress
                    && ev.shippingAddress?.postalCode !== localStorage.getItem('postcode')
                ) {

                    // validate the postcode with our API

                    validatePostcode(
                        operatorName,
                        ev.shippingAddress.postalCode,
                        operatorContext.operator?.flow ?? 'noflow',
                        'delivery',
                        basketContext.validatedBasket?.basket_id
                    )
                        .then((sites: TSite[]) => {

                            if (sites.length !== 1) {

                                toastError('Sorry, the delivery postcode given by ' + libraryInUse +
                                    ' (' + ev.shippingAddress?.postalCode + ') ' +
                                    'isn\'t serviced by this restaurant, and it\'s ' +
                                    'different from the postcode entered previously (' + localStorage.getItem('postcode') + ').' +
                                    ' Please try again or enter your card details manually.');

                                throw new Error('exit');
                            } else {
                                localStorage.setItem('postcode', ev.shippingAddress?.postalCode ?? '');
                            }
                        });
                }*/

                // --- all OK re delivery address ---

                // add address to object..
                // dataObject.address1 = ev.shippingAddress?.addressLine?.join(', ');
                // dataObject.postcode = ev.shippingAddress?.postalCode;
                // dataObject.city = ev.shippingAddress?.city;

                // if (siteContext.channel !== config.dineIn.name && ev.payerPhone) {
                //     dataObject.tel = ev.payerPhone;
                // }

                iLog('Data object', dataObject);

                // Get order data with this payment method
                let orderData = orderContext.getOrderData(dataObject, ev.paymentMethod);

                if (basketContext.validatedBasket?.ready_time) {
                    orderData = { ...orderData, ...{ time: basketContext.validatedBasket?.ready_time?.id } };
                } else if (basketContext.timeslot) {
                    orderData = { ...orderData, ...{ time: basketContext.timeslot.id } };
                }

                // Handle case where timeslot wasn't set somehow..
                if (orderData.time === '') {
                    ev.complete('success');
                    //siteContext.fetchTimeslots(siteContext.site?.uid, siteContext.flow);
                    setTimeslotShow(true);
                    return;
                }

                // Remember me
                rememberMe(values);

                // Payment method retrieved, proceed with ordering
                try {
                    ev.complete('success');
                    await orderContext.processOrder(orderData, stripe);
                } catch (error) {
                    ev.complete('fail');
                    console.error('Device payment failed.', error);
                }
            });
        }
        //}, [stripe, values, isValid]);
    }, [stripe, basketContext.validatedBasket]);

    const getErrorText = (error: {
        type: 'validation_error';
        code: string;
        message: string;
    } | undefined) => {
        if (error) {
            return errorDictionary[error.code as keyof ErrorDictionary] ? errorDictionary[error.code as keyof ErrorDictionary] : error.message;
        } else {
            return ''
        }
    }

    const rememberMe = (orderData: any) => {
        if ( orderData.remember_me ) {
            localStorage.setItem('user', JSON.stringify({
                "firstName": orderData.firstName,
                "lastName": orderData.lastName,
                "email": orderData.email,
                "tel": orderData.tel,
                "remember_me": true
            }));
        } else {
            localStorage.removeItem('user');
        }
    }

    const onCardChange = (event: StripeElementChangeEvent, type: 'card' | 'cvc' | 'expiry') => {

        if (type === 'cvc') {
            setCardErrors(currentErrors => ({
                ...currentErrors,
                cardCvc: getErrorText(event.error),
            }));
        } else if (type === 'expiry') {
            setCardErrors(currentErrors => ({
                ...currentErrors,
                cardExpiry: getErrorText(event.error),
            }));
        }


        if (event.complete) {
            validateForm();
            setCard((prev) => ({
                ...prev,
                [type]: true,
            }));

            if (event.elementType === "cardExpiry") {
                elements?.getElement(CardCvcElement)?.focus();
            }
        } else {
            setCard((prev) => ({
                ...prev,
                [type]: false,
            }));
        }
    };

    const onCardChangeCardNumber = (event: StripeCardNumberElementChangeEvent) => {
        setCardErrors(currentErrors => ({
            ...currentErrors,
            cardNumber: getErrorText(event.error),
        }));
        if (event.complete) {
            elements?.getElement(CardExpiryElement)?.focus();
            validateForm();
            setCard((prev) => ({
                ...prev,
                card: true,
            }));
        } else {
            setCard((prev) => ({
                ...prev,
                card: false,
            }));
        }

        if ( event.brand === 'visa' ) {
            setCardBrand(visa);
        } else if ( event.brand === 'amex' ) {
            setCardBrand(amex);
        } else if ( event.brand === 'mastercard' ) {
            setCardBrand(mastercard);
        }
    };

    const handleCardPayment = async () => {
        orderContext.setLoading(true);

        // Submit customer details
        await submitForm().catch((error) => {
            console.error('error42', error);
            throw new error();
        });

        iLog('is fullyDiscountedOrder?', [fullyDiscountedOrder, paymentType]);

        if (fullyDiscountedOrder) {
            return handleNoCardPayment();
        }

        iLog('continue?', [!stripe, !elements, !orderContext.orderData, !isValid]);

        if (!stripe || !elements || !orderContext.orderData || !isValid) {

            //console.log('stop', errors);

            validateForm().then((r) => {

                if ( Object.keys(r).length !== 0 ) {
                    const el = document.getElementById(Object.keys(r)[0]);
                    el?.focus();
                    el?.scrollIntoView({block: "start", behavior: "smooth"});
                }

            });


            orderContext.setLoading(false);
            return;
        }

        let card = elements.getElement(CardNumberElement);

        iLog('card?', card);

        if (!card) {
            console.error('No card');
            orderContext.setLoading(false);
            return;
        }

        //   checkoutData.flow = order.flow;

        iLog('paymentType', paymentType);

        if (basketContext.basket && basketContext.basket.total !== 0) {

            // Use your card Element with other Stripe.js APIs
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: card,
            });

            if (error) {
                console.error('error41', error); //TODO
                orderContext.setLoading(false);
                return;
            }

            iLog('paymentMethod success', paymentMethod);

            // Get order data with this payment method
            let orderData = orderContext.getOrderData(values as CustomerFormObject, paymentMethod);

            iLog('orderData', orderData);

            rememberMe(values);

            // 2022-11-22...
            //
            // if (basketContext.validatedBasket?.ready_time) {
            //     orderData = { ...orderData, ...{ time: basketContext.validatedBasket?.ready_time?.id } };
            // } else if (basketContext.timeslot) {
            //     orderData = { ...orderData, ...{ time: basketContext.timeslot.id } };
            // }

            iLog('Processing order with customer', orderData);

            setValidationErrors([]);

            // Payment method retrieved, proceed with ordering

            mixpanel.track('Attempting to pay');

            try {

                await orderContext.processOrder(orderData, stripe);

            } catch (error: any) {
                console.error('Payment failed.', error);

                if (error.code === 851 ) { // inline validation errors

                    // validation error comes in as a json string
                    let error_obj = JSON.parse(error.message);

                    iLog('error obj', error_obj);

                    let keys = Object.keys(error_obj);
                    let parsed_obj: TError[] = [];

                    iLog('keys', keys);

                    keys.forEach((key, index) => {
                        iLog('setting error', [key, error_obj[key]]);
                        parsed_obj.push({
                            field: key,
                            messages: error_obj[key]
                        });


                    });
                    setValidationErrors(parsed_obj);


                } else {
                    //throw error;
                }

            }
        }
    };

    const handleNoCardPayment = async () => {

        if (!orderContext.orderData) {
            orderContext.setLoading(false);
            return;
        }


        // Get order data with this payment method
        let orderData = orderContext.getOrderData(values as CustomerFormObject);

        if (basketContext.validatedBasket?.ready_time) {
            orderData = { ...orderData, ...{ time: basketContext.validatedBasket?.ready_time?.id } };
        } else if (basketContext.timeslot) {
            orderData = { ...orderData, ...{ time: basketContext.timeslot.id } };
        }

        iLog('Processing order with customer', orderData.customer);

        // Payment method retrieved, proceed with ordering

        mixpanel.track('Attempting to order (no payment)');
        try {
            await orderContext.processOrder(orderData);
        } catch (error) {
            console.error('Payment failed 2.', error);
        }
    };

    const renderContactFormElements = () => {
        const v: any = values;
        const th: any = touched;
        const e: any = errors;

        return (
            <>
                { !fullyDiscountedOrder && (<FormHeading first={true}>{t`Contact`}</FormHeading>) }
                {form.firstName ? (
                    <Form.Group controlId="firstName">
                        <Form.Control
                            type="text"
                            name="firstName"
                            value={v.firstName}
                            onBlur={handleBlur}
                            ref={nameInputRef}
                            onChange={handleChange}
                            isValid={th.firstName && !e.firstName}
                            isInvalid={th.firstName && !!e.firstName}
                            placeholder={t`First name`}
                            disabled={orderContext.loading}
                            style={{ scrollMarginTop: '74px' }}
                        />
                        <Form.Control.Feedback type="invalid">{e.firstName}</Form.Control.Feedback>
                        <FeedbackElement
                            className={`invalid-feedback ${validationErrors?.filter((m) => m.field === 'first_name') ? 'show-important' : ''}`}>
                            {validationErrors?.filter((m) => m.field === 'first_name').map((m) => m.messages)}
                        </FeedbackElement>
                    </Form.Group>
                ) : null}

                {form.lastName ? (
                    <Form.Group controlId="lastName">
                        <Form.Control
                            type="text"
                            name="lastName"
                            value={v.lastName}
                            onBlur={handleBlur}
                            ref={lastNameInputRef}
                            onChange={handleChange}
                            isValid={th.lastName && !e.lastName}
                            isInvalid={th.lastName && !!e.lastName}
                            placeholder={t`Last name`}
                            disabled={orderContext.loading}
                            style={{ scrollMarginTop: '74px' }}
                        />

                        <Form.Control.Feedback type="invalid">{e.lastName}</Form.Control.Feedback>
                        <FeedbackElement
                            className={`invalid-feedback ${validationErrors?.filter((m) => m.field === 'last_name') ? 'show-important' : ''}`}>
                            {validationErrors?.filter((m) => m.field === 'last_name').map((m) => m.messages)}
                        </FeedbackElement>
                    </Form.Group>
                ) : null}

                {form.email ? (
                    <Form.Group controlId="email">
                        <Form.Control
                            type="email"
                            name="email"
                            value={v.email}
                            ref={emailInputRef}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isValid={th.email && !e.email}
                            isInvalid={th.email && !!e.email}
                            placeholder={t`Email address for receipt`}
                            disabled={orderContext.loading}
                            style={{ scrollMarginTop: '74px' }}
                        />

                        <Form.Control.Feedback type="invalid">{e.email}</Form.Control.Feedback>
                        <FeedbackElement
                            className={`invalid-feedback ${validationErrors?.filter((m) => m.field === 'email') ? 'show-important' : ''}`}>
                            {validationErrors?.filter((m) => m.field === 'email').map((m) => m.messages)}
                        </FeedbackElement>
                    </Form.Group>
                ) : null}

                {form.phone ? (
                    <Form.Group controlId="tel" className='phonenum'>
                        <Form.Control
                            type="tel"
                            name="tel"
                            value={v.tel}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isValid={th.tel && !e.tel}
                            isInvalid={th.tel && !!e.tel}
                            placeholder={t`Phone number for order`}
                            style={ fullyDiscountedOrder ? { marginBottom: '24px', scrollMarginTop: '74px' } : { scrollMarginTop: '74px' } }
                            disabled={orderContext.loading}
                        />
                        <Form.Control.Feedback type="invalid">{e.tel}</Form.Control.Feedback>
                        <FeedbackElement
                            className={`invalid-feedback ${validationErrors?.filter((m) => m.field === 'tel') ? 'show-important' : ''}`}>
                            {validationErrors?.filter((m) => m.field === 'tel').map((m) => m.messages)}
                        </FeedbackElement>
                    </Form.Group>
                ) : null}

                    {/*<Form.Group controlId="cardholderName" className='cardholderName'>
                        <Form.Control
                            type="text"
                            name="cardholderName"
                            value={v.cardholderName}
                            placeholder={t`Cardholder name`}
                            onBlur={handleBlur}
                            onChange={(event) => {
                                handleChange(event);

                                const split = event.target.value.split(' ');

                                v.firstName = split.shift();
                                v.lastName = split.join(' ');

                                validateForm();
                            }}
                            isValid={th.cardholderName && !e.cardholderName}
                            isInvalid={th.cardholderName && !!e.cardholderName}
                        />
                        <Form.Control.Feedback type="invalid">
                            <div>
                                <i className='bx bxs-info-circle' />{e.cardholderName}
                            </div>
                        </Form.Control.Feedback>
                    </Form.Group>*/}

                <StyledOptIn>
                    <Form.Group controlId="remember_me" className={'opt_in_group'}>
                        <Form.Control
                            type="checkbox"
                            name="remember_me"
                            value={v.remember_me}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className={'checkbox'}
                            defaultChecked={v.remember_me}
                        />
                        <svg
                            className={'text-white'}
                            width={14}
                            height={14}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"
                                fill="currentColor"/>
                        </svg>
                        <OptInLabel htmlFor={'remember_me'}>
                            Remember me for a faster checkout
                        </OptInLabel>
                    </Form.Group>
                </StyledOptIn>

                {form.opt_in_marketing && operatorContext.operator?.theme.v3.language.opt_in ? (
                    <StyledOptIn>
                        <Form.Group controlId="opt_in_marketing" className={'opt_in_group'}>
                            <Form.Control
                                type="checkbox"
                                name="opt_in_marketing"
                                value={v.opt_in_marketing}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className={'checkbox'}
                                //style={v.opt_in_marketing ? {accentColor: operatorContext.operator?.theme.v3.ui.other.link} : {}}
                                defaultChecked={v.opt_in_marketing}
                                //defaultChecked={operatorContext.operator?.theme.v3.default_opt_in ?? false}
                                //defaultValue={operatorContext.operator?.theme.v3.default_opt_in ?? false ? 'true' : 'false'}
                            />
                            <svg
                                className={'text-white'}
                                width={14}
                                height={14}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"
                                    fill="currentColor"/>
                            </svg>
                            <OptInLabel htmlFor={'opt_in_marketing'}>
                                { operatorContext.operator?.theme.v3.language.opt_in }
                            </OptInLabel>
                        </Form.Group>
                    </StyledOptIn>
                ) : null}

                {form.address ? (
                    <>

                        <FormHeading className={'form-heading'}>{t`Address`}</FormHeading>

                        <Form.Group controlId="address1">
                            <Form.Control
                                type="address"
                                name="address1"
                                value={v.address1}
                                placeholder={'Address line 1'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isValid={th.address1 && !e.address1}
                                isInvalid={th.address1 && !!e.address1}
                                disabled={orderContext.loading || ['delivery', 'catering'].includes(siteContext.channel) }
                            />
                            <Form.Control.Feedback type="invalid">
                                <div>
                                    <i className='bx bxs-info-circle' />{e.address1}
                                </div>
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="address2">
                            <Form.Control
                                type="address"
                                name="address2"
                                value={v.address2}
                                placeholder={'Address line 2'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isValid={th.address2 && !e.address2}
                                isInvalid={th.address2 && !!e.address2}
                                disabled={orderContext.loading || ['delivery', 'catering'].includes(siteContext.channel) }
                            />
                            <Form.Control.Feedback type="invalid">
                                <div>
                                    <i className='bx bxs-info-circle' />{e.address2}
                                </div>
                            </Form.Control.Feedback>
                        </Form.Group>
                        <StyledRow blockMobile>
                            <Form.Group controlId="city">
                                <Form.Control
                                    type="city"
                                    name="city"
                                    value={v.city}
                                    placeholder={"City/Town"}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isValid={th.city && !e.city}
                                    isInvalid={th.city && !!e.city}
                                    disabled={orderContext.loading || ['delivery', 'catering'].includes(siteContext.channel) }
                                />
                                <Form.Control.Feedback type="invalid">
                                    <div>
                                        <i className='bx bxs-info-circle' />{e.city}
                                    </div>
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="postcode">
                                <Form.Control
                                    type="postcode"
                                    name="postcode"
                                    placeholder={"Postcode"}
                                    ref={postcodeInputRef}
                                    value={v.postcode}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    isValid={th.postcode && !e.postcode}
                                    isInvalid={th.postcode && !!e.postcode}
                                    disabled={orderContext.loading || ['delivery', 'catering'].includes(siteContext.channel)}
                                />
                            </Form.Group>
                        </StyledRow>

                        <div>
                            <ChangePostcode onClick={editPostcode}>Want to change address?</ChangePostcode>
                        </div>

                        <SpecialInstructions className="modal-block">
                            <span className="d-block">{t`Delivery instructions`}</span>
                            <OrderNote placeholder="Add a delivery instruction"
                                maxLength={100}
                                className={'form-control--textarea'}
                                onChange={(e) => orderContext.setDeliveryInstructions(e.target.value) }
                            />
                        </SpecialInstructions>


                    </>
                ) : null}
                {/* <FormCheckbox
                    id="allow_orderswift_emails"
                    label={
                        operatorContext.operator?.opt_in_message
                            ? operatorContext.operator?.opt_in_message
                            : t`I would like to receive emails from ` + `${operatorContext.operator?.name}`
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                /> */}

            </>
        );
    };

    const options = {
        appearance: {
            theme: "flat", // Ensure a theme is set
            variables: {
                fontFamily: "ChelseaRR, sans-serif",
            },
            fonts: [
                {
                    family: "ChelseaRR",
                    src: "url('https://orderswift-staging.s3.eu-west-2.amazonaws.com/themes-v3/ChelseaRRLight.woff2') format('woff2')",
                    weight: "400",
                    style: "normal",
                    display: "swap",
                },
            ],
        },
        style: {
            base: {
                fontSize: "16px",
                color: "#000000",
                fontFamily: "ChelseaRR, sans-serif",
                fontSmoothing: "antialiased",
                fontWeight: "400",
                lineHeight: "46px",
                "::placeholder": {
                    color: "#8E8E93",
                    fontWeight: "400",
                    lineHeight: "22px",
                },
            },
        },
    };

    /*const options = {
        style: {
            base: {
                fontSize: '16px',
                color: '#000000',
                //fontFamily: `Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                fontFamily: `ChelseaRR, sans-serif`,
                fontSmoothing: 'antialiased',
                fontWeight: '400',
                lineHeight: '46px',
                '::placeholder': {
                    color: '#8E8E93',
                    fontWeight: '400',
                    lineHeight: '22px'
                },
            },
        },
    };*/

    const renderMobilePayIcon = () => {
        switch (mobilePayMethod) {
            case 'Google Pay':
                return (<img className={'payment-radio__logo'} src={google_pay}/>)
            case 'Apple Pay':
                return (<img className={'payment-radio__logo'} src={apple_pay}/>)
            case 'Link':
                return (<span>Link</span>)
            default:
                return <span>N/A</span>
        }

    }

    const renderButtonContent = () => {
        if (orderContext.loading) {
            return <StyledSpinnerOverlay show={true}>
                <PageSpinnerThree show={true} color={'#FFFFFF'}/>
            </StyledSpinnerOverlay>
        }

        return (
            <>
                {/*<i className="feather feather-lock feather-20 mr-1" />*/}
                <span>{t`Pay`} { money(orderContext.amount) }</span>
            </>
        );
    };

    const renderPaymentTypes = (paymentType: "mobile" | "card" | "express") => {

        return (
           <PaymentTypes>
               {paymentRequest ? (
                   <div className={"mobileCheckoutWrapper payment-type" + (paymentType === 'mobile' ? ' payment-type--show' : '')}>
                       <PaymentRequestButtonElement
                           className={`mobile-pay-button`}
                           id={'AP-btn'}
                           onClick={(event) => {

                               event.preventDefault();

                               mixpanel.track('Payment request button clicked');

                               validateForm().then((r) => {

                                   if ( Object.keys(r).length === 0 ) {

                                       paymentRequest.show();

                                   } else {

                                       console.log('Your form has errors which need attention!');

                                       const el = document.getElementById(Object.keys(r)[0]);
                                       el?.focus();
                                       el?.scrollIntoView({block: "start", behavior: "smooth"});

                                   }
                               });

                               // needed to display the errors (for some reason) ..
                               submitForm();

                           }}
                           options={
                               {
                                   paymentRequest,
                                   style: {
                                       paymentRequestButton: {
                                           //type: 'default',
                                           // One of 'default', 'book', 'buy', or 'donate'
                                           // Defaults to 'default'

                                           //theme: 'dark',
                                           // One of 'dark', 'light', or 'light-outline'
                                           // Defaults to 'dark'
                                           height: '48px',
                                           // Defaults to '40px'. The width is always '100%'.
                                       },
                                   }
                               }
                           }
                       />
                   </div>
               ) : null}


               <form className={'payment-type' + (paymentType === 'card' ? ' payment-type--show' : '')}>
                   <Form.Group controlId="card">
                       <CardNumberWrapper>
                           <CardNumberElement
                               className={`form-control ${orderContext.loading ? 'disabled-bg': ''}`}
                               onChange={(e: StripeCardNumberElementChangeEvent) => onCardChangeCardNumber(e)}
                               options={{...options, placeholder: 'Card number', disabled: orderContext.loading }}
                           />
                           <i className={'feather feather-lock feather-20'}></i>
                           {/*{cardBrand && (<img src={cardBrand} alt="brand" />) }*/}
                       </CardNumberWrapper>
                       {cardErrors.cardNumber && (
                           <ErrorContainer>
                               {cardErrors.cardNumber}
                           </ErrorContainer>
                       )}
                   </Form.Group>
                   <StyledRow blockMobile>
                       <Form.Group controlId="expiry">
                           <CardExpiryElement
                               className={`form-control ${orderContext.loading ? 'disabled-bg': ''}`}
                               onChange={(e: StripeElementChangeEvent) => onCardChange(e, 'expiry')}
                               options={{...options, placeholder: 'Expiry date', disabled: orderContext.loading }}
                           />
                           {cardErrors.cardExpiry && (
                               <ErrorContainer>
                                   {cardErrors.cardExpiry}
                               </ErrorContainer>
                           )}
                       </Form.Group>

                       <Form.Group controlId="cvc">
                           <CvcWrapper>
                               <CardCvcElement
                                   className={`form-control ${orderContext.loading ? 'disabled-bg': ''}`}
                                   onChange={(e: StripeElementChangeEvent) => onCardChange(e, 'cvc')}
                                   options={{...options, placeholder: 'Security code', disabled: orderContext.loading }}
                               />
                               <OverlayTrigger
                                   key={'cvcPlacement'}
                                   placement={'top'}
                                   overlay={
                                       <Tooltip id={`tooltip-cvcPlacement`}>
                                           3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front.
                                       </Tooltip>
                                   }
                               >
                                   <i className={'feather feather-help-circle feather-20'}></i>
                               </OverlayTrigger>

                               {/*<div className="images">*/}
                               {/*    <img src={cvc} alt="" />*/}
                               {/*</div>*/}

                               {cardErrors.cardCvc && (
                                   <ErrorContainer>
                                       {/*<i className="feather feather-info feather-20 color-filter-invalid" />*/}
                                       {cardErrors.cardCvc}
                                   </ErrorContainer>
                               )}
                           </CvcWrapper>
                       </Form.Group>
                   </StyledRow>
               </form>

               <CTA
                   disabled={ isSubmitting }
                   // disabled={ !cardComplete || orderContext.loading || !isValid || isSubmitting }
                   onClick={handleCardPayment}
                   block
                   className={`justify-content-center payBtn font-cta payment-type ${paymentType === 'card' ? 'payment-type--show' : ''} ${!cardComplete ? 'check-card-incomplete' : ''} ${!cardComplete ? 'check-card-incomplete' : ''} ${orderContext.loading ? 'order-loading' : ''} ${!isValid ? 'is-invalid' : ''} ${isSubmitting ? 'is-submitting' : ''}`}
               >
                   {renderButtonContent()}
               </CTA>
           </PaymentTypes>
        )
    }


    return (
        <StyledContainer>
            <TermsModal
                show={termsShow}
                onHide={() => { setTermsShow(false); }}
            />

            <PrivacyModal
                show={privacyShow}
                onHide={() => { setPrivacyShow(false); }}
            />

            <TimeslotModal show={timeslotShow} onHide={handleTimeslotClose} />

            {form.address ? <ChangeAddressModal show={addressModalShow} onHide={() => {setAddressModalShow(false)}}/> : null}

            {!fullyDiscountedOrder ? (
                <>
                    <div className={`cardCheckoutWrapper ${paymentType !== 'card' ? '' : ''} ${orderContext.loading ? 'form--submitting' : ''}`}>
                        {/* <h5>{t`Contact`}</h5> */}
                        <FormCard>
                            {renderContactFormElements()}
                        </FormCard>

                        <Zillionaire page={'payment'} />

                        <FormCard>
                            { !fullyDiscountedOrder && (<FormHeading>{t`Payment`}</FormHeading>)}
                            <div className={'payment-radio'}>
                                <RadioGroup selected={paymentType === "card"} onChange={() => setPaymentType('card')} label={'Debit or credit card'} label_xs={'Card'} icon={<img className={'payment-radio__cards'} src={cards}/>} />
                                {mobilePayEnabled && mobilePayMethod ? ( <RadioGroup selected={paymentType === "mobile"} onChange={() => setPaymentType('mobile')} label={"Pay with " + mobilePayMethod} icon={renderMobilePayIcon()}/>) : null}
                            </div>
                            {renderPaymentTypes(paymentType)}

                            <div className="privacy-notice">
                                By placing an order, you agree to be bound by our <span onClick={() => setTermsShow(true)}>Terms of Service</span> and
                                our <span onClick={() => setPrivacyShow(true)}>Privacy Policy</span>.
                            </div>
                        </FormCard>
                    </div>
                </>
            ) : null}
            {fullyDiscountedOrder ? (
                <>
                    <FormCard>
                        {/*<Card.Body>*/}
                        {/*    <input*/}
                        {/*        type="radio"*/}
                        {/*        className={'mr-2'}*/}
                        {/*        checked={paymentType === 'mobile'}*/}
                        {/*        id={'pay_type_mobile'}*/}
                        {/*        value={'mobile'}*/}
                        {/*    />*/}
                        {/*    <span> {t`Pay with your device`}</span>*/}
                        {/*</Card.Body>*/}

                        <h2 style={{ marginBottom: '24px' }}>{t`Contact`}</h2>
                        {renderContactFormElements()}
                        <CTA
                            disabled={ orderContext.loading || !isValid || isSubmitting }
                            onClick={handleCardPayment}
                            block
                        >
                            <PageSpinnerThree show={orderContext.loading} text={t`Place order`} color={'#FFFFFF'} />
                        </CTA>

                        <div className="privacy-notice">
                            By placing an order, you agree to be bound by our <span onClick={() => setTermsShow(true)}>Terms of Service</span> and
                            our <span onClick={() => setPrivacyShow(true)}>Privacy Policy</span>.
                        </div>
                    </FormCard>
                </>
            ) : null}





        </StyledContainer>
    );
};


const SpecialInstructions = styled.div`
    span {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
`;

const OrderNote = styled.textarea`
    border: 1px solid var(--border-grey);
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 84px;
    resize: none;
    margin-top: 8px;

    &::placeholder {
        color: var(--text-grey);
        font-size: 16px;
    }
`;

const FormCard = styled.div`
    padding: 16px 20px;
    margin-bottom: 16px;
    background-color: var(--white);
    border-bottom: 1px solid var(--border-grey);
    @media screen and (min-width: 993px) {
        padding: 0;
        border-bottom: 0;
    }
`;

const PaymentTypes = styled.div`
    .payment-type {
        display: none;
        &--show {
            display: block;
        }
    }
`;

const StyledRow = styled.div<{blockMobile?: boolean}>`
    display: flex;
    gap: 16px;
    width: 100%;
    .form-group {
        width: 100%;
    }
    ${(props) => props.blockMobile ? `@media (max-width: 392px) { display: block }` : ''}
`;

const FeedbackElement = styled.div`
    &.show-important { display:block !important; }
`;

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #EBEBEB;
    transition: opacity 0.1s ease-in-out;
    opacity: ${(props) => props.show ? '1' : '0'};
    border-radius: 6px;
`;

const FormHeading = styled.h2<{first?: boolean}>`
    margin-bottom: 20px;
    padding-top: 16px;
    ${(props) => props.first ? `@media screen and (min-width: 993px) {padding-top: 0}` : ''}
    
    // CUSTOM PP - done
    //-webkit-text-stroke-width: 1px;
    //-webkit-text-stroke-color: #00572e;
    //text-shadow: 1px 1px 0 #00572e, 2px 2px 0 #00572e, 3px 3px 0 #00572e, 4px 4px 0 #00572e, 5px 5px 0 #00572e, 6px 6px 0 #00572e, 7px 7px 0 #00572e, 8px 8px 0 #00572e;
`;

const OptInLabel = styled.label`
    cursor: pointer;
    line-height: 19px;
    input {
        display: none;
    }
    input:checked + label:before {
        background-color: blue;
    }
    i {
        margin-bottom: 4px;
    }
`;

const StyledOptIn = styled.div`
    .opt_in_group {
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
        > span {
            flex-grow: 1;
            font-size: 14px;
            line-height: 18px;
        }
        > div, > span {
            cursor: pointer;
        }
        label {
            color: var(--text-grey);
            margin-bottom: 0;
            user-select: none;
            font-size: 14px;
            line-height: 19px;
        }
        width: 100%;
        position: relative;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
         
        svg {
            pointer-events: none;
            position: absolute;
            top: 2px;
            left: 2px;
        }
    }
    
    
    .checkbox {
        padding: 0;
        height: 18px;
        width: 18px;
        flex-shrink: 0;
        margin: 0;
        border-radius: 4px;
        // border: 1px solid ${(props) => props.theme.v3.ui.other.link} !important;        
        border: 2px solid var(--border-input) !important;        
        
        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
        cursor: pointer;
        
        &:checked { 
            // accent-color: ${(props) => props.theme.v3.ui.other.link};
            background-color: ${(props) => props.theme.v3.ui.other.link}; 
            border:none !important;
        }

        &:focus:not([disabled]) { 
          border-color: transparent !important;
          box-shadow: none !important;
        }
        &:focus:not(:checked) {
            border-color: var(--border-input) !important;        
        }
        
            
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
`;

const CardNumberWrapper = styled.div`
    position: relative;
    i {
        position: absolute;
        right: 14px;
        top: 14px;
        max-height: 20px;
        height: 20px;
        color: var(--text-grey);
    }
`;

const CvcWrapper = styled.div`
    position: relative;
     .feather-help-circle {
        position: absolute;
        right: 14px;
        top: 14px;
        max-height: 20px;
        height: 20px;
        color: var(--text-grey);
    }
    
`;

const StyledContainer = styled.div`

    // temporary fix
    
    .fake_mobile_payment {
    position:relative;
    height: 48px;
    width: 100%;
        button {
            border-radius: 4px;
            opacity: 1;
            background-color: black;
            .GooglePayButton {
                border: 0;
                cursor: pointer;
                max-height: 64px;
                min-height: 32px;
                position: relative;
                &-logo {
                    background-origin: content-box;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    bottom: 0;
                    left: 0;
                    padding: 12px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background-image: url("https://js.stripe.com/v3/fingerprinted/img/dark-8191afec51483e108a2dc5f17fb0efd0.svg");
                }
            }
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    // background-color: ${(props) => props.theme.color_bg};
    border-radius: 20px;
    margin-bottom: 0px;

    padding: 0px;
    @media screen and (min-width: 993px) {
        padding: 0;
    }


    .checkout__title {
        vertical-align: middle;
        margin-bottom: 0;
    }


    .payBtn {
        margin-top: 8px;
        padding: 10px 24px;
        height: 48px;

        &:disabled {
            background-color: var(--btn-grey);
        }

    }

    .cardCheckoutWrapper {
        &.disabled {
            position: relative;

            ::after {
                content: '';
                position: absolute;
                inset: 0;
                background-color: white;
                opacity: 0.6;
            }

            .form-control,
            button {
                pointer-events: none;
                position: relative;

                /* ::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background-color: white;
                    opacity: 0.8;
                } */
            }
        }
    }

    .mobileCheckoutWrapper {
        margin-top: 24px;
        @media screen and (max-width: 768px) {
            /* margin-bottom: 18px; */
        }

        .separator.belowQuickCheckout {
                /* style={{margin: '24px 0 24px 0'}} */
                margin: 24px 0 24px 0;

                @media screen and (max-width: 768px) {
                    margin: 24px 0 22px 0;
                }
            }

        .mobile-pay-button {

            &.disabled {
                position: relative;
                /* background: gray !important; */
                ::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background-color: white;
                    opacity: 0.5;
                }

                input {
                    pointer-events: none;
                }
            }
        }
    }

    .mobile-pay-button {
        @media screen and (max-width: 768px) {
            /* margin-bottom: 2px; */
        }
    }

    .checkoutTypeWrapper {
        margin-top: 0;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        .checkoutType {
            background: url(${visa}) -40px top no-repeat;

            &::checked + label span {
                background: url(${visa}) -40px top no-repeat;
            }
        }
        .radio {
            cursor: pointer;
            margin-right: 8px;
            position: relative;
            /* top: 2px; */
        }

        span {
            font-size: 14px;
            font-weight: 500;
            margin-left: 0px;

            @media screen and (max-width: 768px) {
                position: relative;
                top: 1px;
            }
        }
    }
    
    .privacy-notice {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        color: var(--text-grey);
        padding: 16px 0;
        span {
            color: var(--text-grey);
            text-decoration: underline;
            font-weight: 500;
            &:hover {
                cursor: pointer;
            }
        }   
        img.cardsImg {
            @media screen and (max-width: 768px) {
                position: relative;
                top: 2px;
            }
        }
    }
    
    .payment-radio {
        margin-bottom: 20px;
        &__logo {
            height: 24px;
        }
    }
`;

const ChangePostcode = styled.div`
    text-align: right;
    font-size: 14px;
    line-height: 10px;
    position: relative;
    top: -8px;
    color: var(--text-grey);
    &:hover {
        cursor: pointer;
    }
`;

const ErrorContainer = styled.div`
    color: var(--invalid);
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
    img { margin-right: 12px; }
    
    & i { margin-right: 8px; }
`;

export default PaymentForm;
