import {t} from "ttag";
import {Accordion, AccordionCollapse, AccordionContext, useAccordionToggle} from "react-bootstrap";
import BasketItem from "../../Menu/components/basket/BasketItem";
import React, {useContext} from "react";
import useOrderContext from "../../../../contexts/order/useOrderContext";
import {Item} from "../../../../contexts/site/site-context";
import styled from "styled-components";
import {money} from "../../../../services/format";
import visa from "../../../../assets/images/visa.svg";
import mastercard from "../../../../assets/images/mastercard.svg";
import amex from "../../../../assets/images/amex.svg";
import card_icon from "../../../../assets/images/feather/credit-card.svg";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";

const OrderSummary = () => {

    const operatorContext = useOperatorContext();
    const orderContext = useOrderContext();

    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <button
                type="button"
                className={`customToggle ${isCurrentEventKey ? 'show' : ''}`}
                onClick={decoratedOnClick}
            >
                {children}
                <i className="feather feather-chevron-down feather-20 brand-color-filter"/>
            </button>
        );
    }

    const orderItems = () => {
        return orderContext.order?.basket ?? [];
    }

    const service = () => {
        return orderContext.order?.service ?? 0;
    }

    const delivery = () => {
        return orderContext.order?.delivery.fee ?? 0;
    }

    const gratuity = () => {
        return orderContext.order?.gratuity ?? 0;
    }

    const subtotal = () => {
        return orderContext.order?.subtotal ?? 0;
    }

    const total = () => {
        return orderContext.order?.total ?? 0;
    }

    const renderPromoDiscountLine = () => {

        return orderContext.order?.codes
            .filter((code) => code.amount > 0 )
            .map((code) => {
                return (
                    <LineItem text={code.text} value={-code.amount}/>
                )
            });
    };

    const renderGiftCardLine = () => {

        if ( !orderContext.order?.payments?.filter((pa) => pa.type === 'giftcard')[0] ) {
            return null;
        }

        let text = `Giftcard`;
        let discount = orderContext.order?.payments?.filter((pa) => pa.type === 'giftcard')[0].amount

        return ( <LineItem text={text} value={-discount}/> );
    };

    const basketItemsCountStr = () => {
        let count = 0;
        orderItems().forEach((item: Item) => {
            count += item.quantity ?? 0;
        });
        return count + ' item' + (Number(count) > 1 ? 's' : '');
    }

    interface LineItemProps {
        text: string,
        value: number,
        bold?: boolean
    }

    const LineItem = ({text, value, bold}: LineItemProps) => {
        return (
            <div className={'conf__receipt-item' + (bold ? ' conf__receipt-item--bold' : '')}>
                <span className={'conf__receipt-item__name'}>{text}</span>
                <span className={'conf__receipt-item__value'}>{money(value, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
            </div>
        )
    }

    const paymentDetails = () => {
        return {
            last4: orderContext.order?.payments.filter((pa) => pa.type !== 'giftcard')[0].last4 ?? '',
            brand: orderContext.order?.payments.filter((pa) => pa.type !== 'giftcard')[0].type ?? ''
        }
    }


    const getCardImage = (card:String) => {
        switch (card) {
            case 'visa':
                return visa;
            case 'mastercard':
                return mastercard;
            case 'amex':
                return amex;
            default:
                return card_icon
        }
    }

    return (
        <StyledBlock noMobilePadding={true}>
            {/*<h4 className={'conf__heading conf__heading--order'}>{t`Order summary`}</h4>
            <StyledAccordion defaultActiveKey="-1">
                <CustomToggle eventKey="0">
                    <div className="toggle">
                        <span className="toggle__title">Order summary</span>
                        <span>{basketItemsCountStr()}</span>
                        <span className="toggle__price">
                            {money(total())}
                        </span>
                    </div>
                </CustomToggle>

                <AccordionCollapse eventKey="0">
                    <BasketItems>
                        {orderItems().map((item, index) => (
                            <BasketItem
                                key={`basket-${index}-${item.uid}`}
                                handleClick={() => {
                                }}
                                item={item}
                                checkout={true}
                                last={index === orderItems().length - 1}
                            />
                        ))}
                    </BasketItems>
                </AccordionCollapse>
            </StyledAccordion>*/}
            <div className={'conf__receipt-items'}>
                {/*<LineItem text={'Subtotal'} value={subtotal()}/>*/}
                {/*{
                    (orderContext.order?.service ?? 0) === 0
                        ? null
                        : <LineItem text={'Service'} value={service()}/>
                }
                {
                    (orderContext.order?.delivery?.fee ?? 0) === 0
                        ? null
                        : <LineItem text={'Delivery Fee'} value={delivery()}/>
                }
                {
                    (orderContext.order?.gratuity ?? 0) === 0
                        ? null
                        : <LineItem text={'Gratuity'} value={gratuity()}/>
                }*/}
                <>{renderPromoDiscountLine()}</>
                {renderGiftCardLine()}
                <LineItem bold text={'Total'} value={total()}/>
            </div>
            {/*Only show if total > 0*/}
            {total() > 0 ? (
                <PaymentCardDetails className={'basket__payment-info'}>
                    <div className={"basket__payment-info__text"}>
                        Paid with
                    </div>
                    <div className={"basket__payment-info__card"}>
                        <img width={'32'} height={'24'} src={getCardImage(paymentDetails().brand)}/>
                        <span className={''}>••••{paymentDetails().last4}</span>
                    </div>
                </PaymentCardDetails>
            ) : null}

        </StyledBlock>
    );
};

const StyledBlock = styled.div<{bg_var?: string, borderTop?: boolean, noMobilePadding?: boolean}>`
    width: 100%;
    ${(props) => props.noMobilePadding ? null : "padding: 20px;"}
    background-color: var(--${(props) => props.bg_var ?? 'white'});
    ${(props) => props.borderTop ? 'border-top: 1px solid var(--border-grey);' : null}
    @media screen and (min-width: 993px) {
        padding: 20px;
    }
`;

const PaymentCardDetails = styled.div`
    
    &.basket__payment-info {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 20px;
        font-size: 16px;
        font-weight: 500;
        border-top: 1px solid var(--border-grey);
        @media screen and (min-width: 993px){
            padding: 20px 0 0 0;
        }
    }
    .basket__payment-info {
        &__text {
            flex-grow: 1;
        }
        &__card {
            display: flex;
            align-items: center;
            gap: 16px;
           
        }
    }
    
`;

const BasketItems = styled.div`
        padding: 12px 0;
    `;

const StyledAccordion = styled(Accordion)`
    .customToggle {
        transition: background-color 0.15s ease-in-out;
        i { 
            transition: transform 0.15s ease-in-out;
        }
        &.show {
            i {
                transform: rotate(180deg);
            }
        }
        @media screen and (min-width: 993px) {
            // display: none;
        }
    }
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    .collapse, .collapsing {
        border-bottom: 1px solid var(--border-grey);
        @media screen and (min-width: 993px) {
            border-bottom: 0;
        }
    }
    > button {
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        display: flex;
        padding: 20px;
        border-bottom: 1px solid var(--border-grey);
        @media screen and (min-width: 993px) {
            padding: 20px 0;
            border-bottom-width: 0;
            border-top: 1px solid var(--border-grey);
            &.show {
                border-bottom-width: 1px;
            }
        }
        margin: 0;
        font-weight: 600;
        align-items: center;
        color: var(--text-black);
        text-align: left;

        img {
            transition: transform 0.2s linear;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }

        &.show {
            .toggle {
            }
        }

        .toggle {
            width: 100%;
            display: flex;
            /* margin-left: 16px; */
            color: ${(props) => props.theme.shade_0};
            flex-wrap: wrap;
            flex-grow: 1;
            > span {
                margin-right: 8px;
                color: var(--brand);
            }
            font-size: var(--core_text_ui_lg);
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
            font-weight: 500;
            line-height: 20px;

            &__title {
                font-weight: 500;
            }
            &__price {
                flex-grow: 1;
                text-align: right;
            }

            @media screen and (min-width: 768px) {
                /* margin-left: 24px; */
            }
        }
    }
`;

export default OrderSummary;