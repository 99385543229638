import React, {useContext, useState} from 'react';
import {Accordion, AccordionCollapse, AccordionContext, Form, useAccordionToggle} from 'react-bootstrap';
import styled from 'styled-components';
import useBasketContext from '../../../../contexts/basket/useBasketContext';
import { CTA } from '../../../general/Button';
import {t} from "ttag";
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import useCustomerContext from "../../../../contexts/customer/useCustomerContext";
import {iLog} from "../../../../index";
import mixpanel from "mixpanel-browser";
import {money} from "../../../../services/format";
import BasketItem from "../../Menu/components/basket/BasketItem";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";


const PromoForm = () => {
    const [loading, setLoading] = useState(false);
    const [promoCode, setPromoCode] = useState('');

    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const customerContext = useCustomerContext();


    const addPromoCode = () => {
        setLoading(true);

        const email = basketContext.customerEmail ?? customerContext.customerFormData.email;

        iLog('customer email', [email, basketContext.customerEmail, customerContext.customerFormData.email]);

        basketContext.validatePromoCode(promoCode, email)
            .finally(() => {
                setLoading(false);
                setPromoCode('');
                mixpanel.track('Promo applied');
            });

    };

    const removePromo = async () => {
        mixpanel.track('Removing promo');
        try {
            basketContext.setPromoError('');
            await basketContext.validateBasket(true);
        } catch (error) {
            // if (error.code && [
            //         APIErrorCode.TimeslotMismatch,
            //         APIErrorCode.TimeslotPast,
            //         APIErrorCode.TimeslotExpired
            //     ].includes(error.code)) {
            //     console.log('Timeslot error - show the modal?');
            //     if (showTimeslotModal) {
            //         showTimeslotModal(true);
            //     }
            // }
            basketContext.setLoading(false);
        }
    };


    const renderPromoElement = () => {

        if ( !basketContext.validatedBasket ) {
            return null;
        }

        return basketContext.validatedBasket?.codes
            .map((discount) => {
                return (
                    <StyledPromoLine key={discount.type + discount.text}>
                        <div className={'promo__details'}>
                            <span className="promo__type">{discount.text}</span>
                            { discount.type !== 'pos' ? (
                                <Code>
                                    <div>
                                        <span>{ discount.reference }</span>
                                        <i onClick={removePromo}
                                           className="cursor-pointer feather feather-x feather-14 brand-color-filter"/>
                                    </div>
                                </Code>
                            ) : null }
                        </div>
                        <span className="promo__value">{money(-discount.amount, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                    </StyledPromoLine>
                );
            });
    };

    if (basketContext.validatedBasket?.codes.length) {
        return (<>{renderPromoElement()}</>)
    }


    function CustomToggle({ children, eventKey, callback }: any) {
        const currentEventKey = useContext(AccordionContext);

        const isCurrentEventKey = currentEventKey === eventKey;

        const decoratedOnClick = useAccordionToggle(eventKey, () => {
            // clear out any errors when the accordion opens..
            if ( !isCurrentEventKey ) {
                basketContext.setPromoError('');
            }
            callback && callback(eventKey);
        });

        return (
            <button
                type="button"
                className={`your-order-toggle customToggle ${isCurrentEventKey ? 'show' : ''}`}
                onClick={decoratedOnClick}
            >
                {children}
                <i className="feather feather-chevron-down feather-24 brand-color-filter"/>
            </button>
        );
    }



    return (
        <>
            <StyledAccordion defaultActiveKey="-1">
                <CustomToggle eventKey="0">
                    <div className="toggle">
                        <span className="toggle__title">Add a promo or gift card code</span>
                    </div>
                </CustomToggle>
                <AccordionCollapse eventKey="0" timeout={2000}>
                    <>
                        <StyledGroup controlId="formCheckoutPromo" className="promo">
                            <Form.Control
                                placeholder="Add promo or gift card code"
                                className=""
                                value={promoCode}
                                onChange={(e) => setPromoCode(e.target.value)}>
                            </Form.Control>
                            <i className={'feather feather-tag feather-20 promo__icon'}/>

                            {promoCode.length > 0 ? (
                                <CTA
                                    className="font-cta submit-promo-btn"
                                    disabled={loading}
                                    onClick={() => addPromoCode()}
                                    block
                                >
                                    <PageSpinnerThree show={loading} text={t`Apply`} color={'#FFFFFF'} />
                                </CTA>
                            ) : null }
                        </StyledGroup>

                        { basketContext.promoError !== '' && (
                            <ErrorContainer>
                                <i className="feather feather-info feather-20 color-filter-invalid" />
                                { basketContext.promoError }
                            </ErrorContainer>
                        ) }
                    </>
                </AccordionCollapse>
            </StyledAccordion>


        </>
    );
};


const StyledAccordion = styled(Accordion)`
    padding-bottom: 16px;
    .customToggle {
        transition: background-color 0.15s ease-in-out;
        i { 
            transition: transform 0.15s ease-in-out;
        }
        &.show {
            i {
                transform: rotate(180deg);
            }
        }
        @media screen and (min-width: 993px) {
            // display: none;
        }
    }
    .promoSubmit {
        height: 48px;
        margin: 0;
        padding: 0 23px;
        margin-left: 16px;

        @media screen and (min-width: 768px) {
            padding: 0 40px;
            min-width: 152px;
            margin-left: 24px;
        }
    }
    
    > button {
        width: 100%;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        display: flex;
        padding: 0;
        margin: 0;
        font-weight: 600;
        align-items: center;
        color: var(--text-black);
        text-align: left;

        img {
            transition: transform 0.2s linear;
        }

        &.show {
            img {
                transform: rotate(180deg);
            }
        }

        .toggle {
            width: 100%;
            display: flex;
            /* margin-left: 16px; */
            color: ${(props) => props.theme.shade_0};
            flex-wrap: wrap;
            flex-grow: 1;
            > span {
                margin-right: 8px;
                color: var(--brand);
            }
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
        }
    }
`;


const ErrorContainer = styled.div`
    color: var(--invalid);
    padding-top: 8px;
    display: flex;
    align-items: flex-start;
    img { margin-right: 12px; }
    
    & i { margin-right: 8px; }
`;

const Code = styled.div`
    display: inline-block;
    margin-top: 8px;
    div {
        font-weight: 600;
        line-height: 20px;
        border-radius: 4px;
        background-color: var(--brand-10);
        padding: 5px 8px;
        display: flex;
        align-items: center;
        color: var(--brand);
        a {
            color: var(--brand);
        }
        
        span {
            padding-right: 8px;
            font-size: 16px;
            line-height: 19px;
        }
        font-size: 16px;
        span { font-size: 16px; }
    }
`;

const StyledGroup = styled(Form.Group)`
    width: 100%;
    margin: 0;
    position: relative;
    margin-top: 16px;
    .promo-add {
        color: var(--brand);
        cursor: pointer;
        margin-top: 8px;
    }
    input {
        padding-left: 48px;
        &:focus ~ .promo__icon {
            color: var(--text-brand);
        }
        
        @media screen and (min-width: 768px) {
            min-width: 220px;
        }
    }
    button {
        width: auto;
    }
    .submit-promo-btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        color: var(--brand);
        height: auto;
        padding: 4px;
        
        &:disabled {
            background: var(--btn-grey);
            color: white;
        }
    }

    .promo__icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-grey);
    }
`;

const StyledPromoLine = styled.div`
    display: flex;
    gap: 12px;
    font-size: 16px;
    padding-bottom: 16px;
    .promo {
        &__details {
            flex-grow: 1;
        }
        &__type {
            display: block;
        }
    }
`;


export default PromoForm;
