import { createContext } from 'react';
import { TChannel } from '../operator/operator-context';
import { Item, Timeslot, TSite } from '../site/site-context';
import basketItem from "../../components/pages/Menu/components/basket/BasketItem";

export interface TBasket {
    asap: boolean;
    basket_id?: string;
    subtotal: number;
    total: number;
    total_service_selected?: number;
    total_service_unselected?: number;
    ccy: string;
    country: string;
    payment_intent: {
        client_secret: string;
    };
    errors: any[];
    basket: BasketItem[];
    auto_promo: string;
    ready_time: ReadyTime;

    delivery_fee?: number;
    service_fee: number;
    service_optional?: boolean;
    service_default?: boolean;
    promo_discount?: number;
    channel?: TChannel;
    codes: DiscountCode[];
    flow?: string;
    has_age_restricted_item: boolean;
    payments?: number;
    due?: number;
}

export type BasketItem = Item & {
    collection_vat_type: string;
    delivery_vat_type: string;
    dine_in_vat_type: string;
    position: number;
    epos_id: string;
    epos_price_id: null;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
    modifier_ids: any[];
    quantity: number;
    selected_modifiers: any[];
    unselected_default_modifiers: any[];
    mods_total: number;
    item_total: number;
    category: Category;
};

export interface Category {
    id: number;
    uid: string;
    menu_id: number;
    is_live: number;
    hidden_from: null;
    name: string;
    description: string;
    position: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: null;
}

export interface ReadyTime {
    is_asap: boolean;
    id: string;
    name: string;
}

export interface Price {
    deliveryFee: number;
    serviceFee: number;
    subtotal: number;
    total: number;
}

export type Order = {
    uid: string;
    channel: TChannel;
    time: string | undefined;
    asap: boolean;
    basket: string; //OrderBasket[]
    subtotal: number;
    flow: string;
    special_notes: string | null | undefined;
    remove_promo: boolean | undefined;
    address: string | undefined | null;
};

export type TabOrder = {
    site: string;
    basket: Array<any>; //OrderBasket[]
    table_no: string|null;
};

export type OrderBasket = {
    item_id: string;
    quantity: number;
    modifiers: { modifier_id: string }[];
};

export type DiscountCode = {
    type: 'promo' | 'giftcard' | 'pos';
    amount: number;
    text: string;
    reference: string;
};

export type BasketContextData = {
    basket: TBasket | null | undefined;
    setBasket: (basket: TBasket | null) => void;

    basketItems: Item[];
    setBasketItems: (items: Item[]) => void;


    validateBasket: (removePromo: boolean) => void;
    addToTab: () => void;
    getTab: (site: string, table: string) => Promise<any>;
    validateTimeslot: () => void;
    validatePromoCode: (code: string, email: string | null) => Promise<any>;
    validateMemberId: (email: string, member: string) => Promise<any>;

    clearBasket: () => void;
    addItem: (item: Item, quantity: number, edit: boolean) => void;
    removeItem: (item: Item) => void;

    validatedSite: TSite | null | undefined;
    validatedBasket: TBasket | null | undefined;

    price: Price;
    setPrice: (price: Price) => void;

    timeslot: Timeslot | null;
    setTimeslot: (timeslot: Timeslot) => void;

    tableNumber: string | null;
    setTableNumber: (tableNumber: string | null) => void;

    customerEmail: string | null;
    setCustomerEmail: (email: string | null) => void;

    loading: boolean;
    setLoading: (state: boolean) => void;

    error: string;
    setError: (error: string) => void;

    promoError: string;
    setPromoError: (error: string) => void;

    specialNotes: string | null | undefined;
    setSpecialNotes: (specialNote: string | null | undefined) => void;

    check: string;
    setCheck: (error: string) => void;
};

export const BasketContext = createContext<BasketContextData>({
    basket: null,
    setBasket: () => {},

    basketItems: [],
    setBasketItems: () => {},

    validateBasket: () => {},
    addToTab: () => {},
    getTab: () => new Promise<any>((resolve, reject) => {}),
    validateTimeslot: () => {},
    validatePromoCode: () => new Promise<any>((resolve, reject) => {}),
    validateMemberId: () => new Promise<any>((resolve, reject) => {}),
    clearBasket: () => {},
    addItem: () => {},
    removeItem: () => {},

    validatedSite: undefined,
    validatedBasket: undefined,

    price: {
        deliveryFee: 0,
        serviceFee: 0,
        subtotal: 0,
        total: 0,
    },
    setPrice: () => {},

    timeslot: null,
    setTimeslot: () => { },

    tableNumber: null,
    setTableNumber: () => {},

    customerEmail: null,
    setCustomerEmail: () => {},

    loading: false,
    setLoading: () => {},

    error: '',
    setError: () => {},

    check: '',
    setCheck: () => {},

    promoError: '',
    setPromoError: () => {},

    specialNotes: null,
    setSpecialNotes: () => {}
});
