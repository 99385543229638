import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { money } from '../../../../../services/format';
import {CTA, CTAProps} from '../../../../general/Button';
import useOperatorContext from "../../../../../contexts/operator/useOperatorContext";


interface CartButtonProps {
    onClick: () => void;
    classes?: string;
    CTAProps?: CTAProps;
    label: string;
    type: string;
}

const CartButton = ({ onClick, classes, CTAProps, label, type }: CartButtonProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();
    const [showAnim, setShowAnim] = useState(false);
    const [quantity, setQuantity] = useState(0);

    // Trigger animation on quantity change
    // useEffect(() => {
    //     setShowAnim(false);
    //     if (quantity > 0) {
    //         setTimeout(() => {
    //             setShowAnim(true);
    //         }, 500)
    //     }
    // }, [quantity])


    useEffect(() => {
        let count = 0;
        basketContext.basketItems.forEach(item => {
            count += item.quantity ?? 0;
        })
        setQuantity(count);
    }, [basketContext.basketItems])

    const renderButtonContent = () => {

        if ( type === 'bill' ) {
            return (
                <>
                <span className="basket-btn-content">
                    <span className="basket-btn-label" style={{width: '100%', top: '-2px'}}>{label}</span>
                </span>
                </>
            );
        }

        if ( type === 'order-bill') {
            return (
                <>
                <span className="basket-btn-content">
                    <span className="basket-btn-label" style={{width: '100%', top: '1px'}}>{label}</span>
                </span>
                </>
            );
        }

        if ( type === 'view-order') {
            return (
                <>
                <span className="basket-btn-content">
                    <span className="basket-btn-label" style={{width: '100%', top: '1px'}}>{label}</span>
                </span>
                </>
            );
        }

        return (
            <>
                <span className="basket-btn-content" style={{width: '100%', top: '-2px'}}>

                    <span className="basket-btn-label">{t`View order`}</span>
                    <span className="basket-btn-text">
                        <span data-testid="order-sum" className='order-sum'>{money(basketContext.price.total, operatorContext.operator?.ccy, operatorContext.operator?.locale)}</span>
                    </span>
                </span>
            </>
        );
    };

    return (
        <CTAButton
            // disabled={basketContext.loading || ( type === 'order' && !basketContext.basketItems.length)}
            disabled={  basketContext.loading || !basketContext.basketItems.length }
            onClick={onClick}
            className={classes + (showAnim ? " anim--basket-pulse" : '') + (basketContext.price.total > 10000 ? ' basket-btn--lg' : '') + ' cart-button'}
            {...CTAProps}
        >
            {renderButtonContent()}
        </CTAButton>
    );
};

const CTAButton = styled(CTA)`

    // CUSTOM PP - done
    //background-color: var(--ui_buttons_cta_basket_bg);
    height: auto;
    @media screen and (min-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
      //height: 48px !important;
    }
    
    .basket-btn-content {
        padding-left: 0px;
        display: flex;
        flex: 1 1 0%;
        gap: 12px;
        align-items: center;
        .basket-btn-label {
            flex-shrink: 0;
            white-space: nowrap;
        }
       

        @media screen and (max-width: 767px) {
            padding-left: 0;
            justify-content: space-between;
        }
        .basket-btn-divider {
            border-left: 1px solid var(--border-grey);
            margin: 0 8px;
            height: 20px;
            border-color: var(--ui_buttons_cta_basket_color);
            display: none;
        }
        .basket-btn-quantity {
            flex-grow: 1;
            display: flex;
            flex-basis: 50%;
            gap: 8px;
            align-items: center;
            span {
                display: inline-block;
                text-transform: uppercase;
                min-width: 20px;
            }
        }
        .basket-btn-text {
            flex-grow: 1;
            flex-basis: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
           
        }
        
         
    }
    
    &:not(.mobile-cart-btn) {
        height: 40px;
        
        // CUSTOM PP
        height: auto;
        line-height: 1;
        
        padding-left: 16px;
        padding-right: 16px;
        
        .basket-btn-divider {
            display: inline-block !important; 
        }   
        @media screen and (min-width: 768px) {
              .basket-btn-quantity span, .basket-btn-divider {
                   display: none !important;
              }
        }
    }
    
    
    @media screen and (max-width: 767px) {
        &:not(.mobile-cart-btn) {
            display: none !important;
        }
    }
    
    @media screen and (min-width: 768px) {
        .basket-btn-quantity span, .basket-btn-divider {
            display: none !important;
        }
    }
`;


export default CartButton;