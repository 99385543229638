import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import { Form, Modal } from 'react-bootstrap';
import CartButton from "../Menu/components/order/CartButton";
import mixpanel from "mixpanel-browser";
import {money} from "../../../services/format";
import {CTA} from "../../general/Button";
import {useNavigate} from "react-router-dom";
import useOrderContext from "../../../contexts/order/useOrderContext";


type SplitPaymentModalProps = {
    onHide: () => void;
    show: boolean;
    tabOrdersTotal: number;
};


const SplitPaymentModal = ({ show, onHide, tabOrdersTotal }: SplitPaymentModalProps) => {

    const orderContext = useOrderContext();

    const navigate = useNavigate();
    const [splitNumber, setSplitNumber] = useState<string>('');
    const [outstanding, setOutstanding] = useState<number>(tabOrdersTotal);

    useEffect(() => {
        document.getElementById('order-modal')?.classList.add('anim-enter');

        if ( show ) {
            mixpanel.track('show split modal');
        }

        return () => {
            document.getElementById('order-modal')?.classList.remove('anim-enter');
        };
    }, [show]);
    
    
    useEffect(() => {

        if ( show ) {
            setOutstanding(tabOrdersTotal - (splitNumber ? parseFloat(splitNumber) * 100 : 0));
        }

    }, [ show, tabOrdersTotal, splitNumber ])
    


    return (
        <StyledModal
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            backdropClassName="modal-order-backdrop"
            className="order-modal-dialog"
            centered
            id={'order-modal'}
            animation={false}
        >
            <Modal.Header>
                {/*<h4 className='modal__title'>{t`Split your bill`}</h4>*/}
                <div className="modal-close__wrapper">
                    <button className={'modal-close__btn'} role="button" onClick={() => onHide()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className={`order-info`}>


               <div className={'centered-vertically'}>
                   <div className={'total'}>TOTAL BILL<br />{ money(tabOrdersTotal)}</div>
                   <div className={'labelism'}>Enter your split</div>
                   <StyledForm>
                       <Form.Group controlId="splitForm" className="mb-0">
                           <Form.Control
                               size="lg"
                               type="number"
                               className="split-input"
                               value={splitNumber}
                               onChange={(e) => setSplitNumber(e.target.value)}
                               autoFocus
                           />
                       </Form.Group>
                   </StyledForm>
                   <div className={'outstanding'}>
                       <span>Outstanding total</span>
                       <span>{ money(outstanding) }</span>
                   </div>

               </div>

            </Modal.Body>
            <Modal.Footer className={'modal-show-footer-shadow'}>
                <StyledForm>
                    <CTA className="table-input-cta"
                         block
                         disabled={ splitNumber.length === 0}
                         onClick={async () => {
                             orderContext.setAmount(parseFloat(splitNumber) * 100);
                             navigate('/checkout');
                         }}>
                        <span style={{ top: '-1px' }}>Confirm split</span>
                    </CTA>
                </StyledForm>
            </Modal.Footer>
        </StyledModal>
    );
};

const StyledForm = styled(Form)`
    .form-group {
        margin-bottom: 16px;
    }
    width: 100%;
`;


const StyledModal = styled(Modal)`


    .modal-block {
        padding: 20px;
        &:not(:last-child){
            border-bottom: 1px solid var(--border-grey);
        }
    }

    .close {
        left: 16px;
        right: auto;
        opacity: 1 !important;
    }
    .modal-body {
        overflow-y: auto;

        &.modal-shadow-body > div:first-child {
            border-bottom: none !important;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
            transition: box-shadow 0.15s ease-in-out;
        }
        
        .centered-vertically {
            
            display: flex;
            flex-direction: column; /* If children should be stacked */
            align-items: center;
            justify-content: center;
            height: 100%; /* Ensure it takes the height of its parent */
            padding: 0 48px;
            
            .total {
                text-align: center;
                margin-bottom: 128px;
                font-size: 24px;
                line-height: 32px;
                
                
                // CUSTOM PP - not needed
                font-family: var(--text_display_font);
                color: var(--text_display_h4_color);
                
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #00572e;
                text-shadow: 1px 1px 0 #00572e, 2px 2px 0 #00572e, 3px 3px 0 #00572e, 4px 4px 0 #00572e, 5px 5px 0 #00572e, 6px 6px 0 #00572e, 7px 7px 0 #00572e, 8px 8px 0 #00572e;
            }
            
            .labelism {
                text-align: left;
                width: 100%;
                margin-bottom: 12px;
            }
            
            .outstanding {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                margin-top: 12px;
            }

            .split-input {
                text-align: center;
            }
            
        }
        
    }
    .modal-header {
        //border-bottom: 1px solid var(--border-grey) !important;
        transition: box-shadow 0.15s ease-in-out;
        display: flex;
        min-height: 64px;
        align-items: center;
        padding: 16px 20px;
        > div { width: 100%}
        .modal__title {
            margin-bottom: 0;
        }
        &.modal-shadow-top {
            /*box-shadow: 0 4px 20px rgba(0,0,0,0.12);*/
        }

    }

    .modal-footer {
        transition: box-shadow 0.15s ease-in-out;
        padding: 16px 20px !important;
    }

    .modal-body {
        .order-info {
            font-size: 14px;
        }

        padding: 0 !important;

        table {
            table-layout: fixed;
            margin: 1.5rem 0;
            tr td {
                line-height: 30px;
                padding: 0;
                border: 0;
                font-weight: 600;
                &:last-child {
                    font-weight: 400;
                    color: var(--text-black);
                }
            }
        }
    }



`;

export default SplitPaymentModal;
