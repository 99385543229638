import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { t } from 'ttag';
import config from '../../../../config/channels';
import useBasketContext from '../../../../contexts/basket/useBasketContext';
import { TChannel } from '../../../../contexts/operator/operator-context';
import useOperatorContext from '../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../contexts/site/useSiteContext';
import Alert from '../../../general/Alert';
import { LinkButton } from '../../../general/Button';
import SiteModal from './site/SiteModal';
import CartButton from './order/CartButton';
import LeavingModal from "../LeavingModal";
import {iLog} from "../../../../index";
import mixpanel from "mixpanel-browser";


interface MenuNavProps {
    handleClick: () => void;
    channel: TChannel;
    singleSiteAndChannel: boolean;
    cartOpen: () => void;
}

const MenuNav = ({ handleClick, channel, singleSiteAndChannel = false, cartOpen }: MenuNavProps) => {

    const navigate = useNavigate();

    const siteContext = useSiteContext();
    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const themeContext = useContext(ThemeContext);

    const isDineIn = siteContext.channel === config.dineIn.name;

    const [siteShow, setSiteShow] = useState(false);
    const [leavingShow, setLeavingShow] = useState(false);

    const [allowTabs] = useState(siteContext.channel === 'dine_in' && siteContext.site?.channels.dine_in?.allow_tabs );

    const handleLeavingClick = () => {
        iLog('Leaving so soon?', [basketContext.basketItems.length, basketContext.basketItems.length > 0])

        mixpanel.track('Change location clicked');

        if ( basketContext.basketItems.length > 0 ) {
            setLeavingShow(true);
        } else {
            window.scrollTo(0,0);
            navigate(`/${isDineIn ? '' : siteContext.channel}`);
        }
    };


    return (
        <StyledNav className="menu-nav-wrapper">
            <SiteModal show={siteShow} onHide={() => setSiteShow(false)} />
            <NavContent>
                <div className="my-wrap">
                    <div className="nav-btn__btn">
                        <button onClick={() => {
                            mixpanel.track('Back chevron clicked (bill)');
                            navigate(-1);
                        }} className="mr-3">
                            <i className="feather feather-chevron-left feather-24 brand-color-filter"/>
                        </button>
                    </div>
                </div>
                <StyledNavBrand onClick={() => navigate('/')} className="logoClass" />
                <StyledRightPanel>
                    <div className={'overflow-location'}>
                        <StyledLocation
                            role="button"
                            style={{ fontSize: '14px' }}
                            onClick={handleLeavingClick}
                        >
                            <i className="feather feather-map-pin feather-20 color-filter-change-location" />

                            {siteContext.channel === 'collection' && (
                                <>
                                    <span>{siteContext.site?.name}</span>
                                </>
                            )}

                            { ['delivery', 'catering'].includes(siteContext.channel) && <>
                                <span>{ localStorage.getItem('postcode') ?? '/' }</span>
                            </>}

                            {siteContext.channel === 'dine_in' && <>
                                <span>{t`Table `} {basketContext.tableNumber}</span>
                            </>}
                        </StyledLocation>
                    </div>
                    <div className={allowTabs ? 'menu-nav-cta--tabs' : ''}>
                        { false && allowTabs ? (
                            <CartButton
                                CTAProps={{ cart: true }}
                                onClick={async () => {
                                    if ( siteContext.site && basketContext.tableNumber ) {
                                        await basketContext.getTab(siteContext.site.uid, basketContext.tableNumber);
                                    }
                                }}
                                label={'View bill'}
                                type={'bill'}
                            />
                        ) : null}
                        <CartButton
                            CTAProps={{ cart: true }}
                            onClick={cartOpen}
                            label={'View order'}
                            type={'order'}
                        />
                    </div>
                </StyledRightPanel>
                <LeavingModal
                    show={leavingShow}
                    onHide={() => { setLeavingShow(false); }}
                />
            </NavContent>

            <div className="d-md-none d-none">
                {operatorContext.operator?.allergen_info ? (
                    <Alert variant="info" icon={'info'}>
                        <p className="mb-1">{t`Please, read allergen information.`}</p>
                        <p className="mb-0">
                            {' '}
                            <LinkButton
                                color={themeContext.color_info}
                                onClick={handleClick}
                                tight
                                underline
                            >{t`Learn more`}</LinkButton>
                        </p>
                    </Alert>
                ) : null}
            </div>
        </StyledNav>
    );
};



const StyledLocation = styled.div`
    display: none;
    transition: background-color 0.2s ease-in-out;
    
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        height: 36px;
        border-radius: 18px;
        padding: 0 20px 0 16px;
        overflow: hidden;
       
        i { margin-right: 8px; font-weight: 500;}

        span {
            display: inline-block;
            color: var(--ui_buttons_change_location_color);
            font-family: var(--text_ui_font);
            text-transform: var(--text_ui_case);
            font-weight: 500;
            font-size: var(--ui_buttons_change_location_size);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-bottom: 1px solid var(--ui_buttons_change_location_color);
            transition: border-color 0.2s ease-in-out;
        }

    }

`;

const StyledRightPanel = styled.div`
    display: flex;
    gap: 24px;
    min-width: 0;
    align-items: center;
    flex-shrink: 1;
    .overflow-location {
        overflow: hidden;
    }
`;

const StyledNav = styled.div`
  
    position: relative;
    z-index: 100;
    h5 {
        span {
            display: inline-block;
            vertical-align: middle;
        }
        span:first-of-type {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: calc(100% - 25px);
        }
    }
    hr {
        padding: 0;
        margin: 12px 0;
    }
    .change-collection-link {
        background: transparent !important;
        font-weight: 400;
    }
    
    @media screen and (min-width: 768px) {
        padding: 12px 0;
    }
    
    .menu-nav-cta--tabs {
        display: flex; 
        gap: 12px;
    }

`;

const NavContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;

    .my-wrap {
        display: flex;
        width: 100%;
        height: 40px;
    }
    .nav-btn {
        &__btn {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (min-width: 1176px) {
                left: 0px;
            }

            button {
                border: none;
                background-color: #FFFFFF;

                padding: 12px;
                width: 40px;
                height: 40px;
                margin-left: -8px;
                position: relative;
                z-index: 2;
                color: var(--brand);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:hover {
                    text-decoration: none;
                }

                &:hover span {
                    text-decoration: underline
                }

                transition: background-color 0.15s ease-in-out;

                :hover {
                    background-color: var(--brand-10);
                }

                span {
                    font-size: 14px;
                    line-height: 20px;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }
    }
        
`;

const StyledNavBrand = styled.div`
    background-image: var(--logo);
    background-size: contain;
    background-position: center left;
    background-repeat: no-repeat;
    height: var(--logo_height);
    width: 290px;
    max-width: 85vw;
    display: block;
    cursor: pointer;
    flex-shrink: 0;

    display: none;
`;


export default MenuNav;