import React, { useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import {Item} from '../../../../../contexts/site/site-context';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import { money } from '../../../../../services/format';
import CheckoutButton from '../basket/CheckoutButton';
import OrderItem from '../order/OrderItem';
import { Form, Modal } from 'react-bootstrap';
import TimeslotSelect from '../../../../timeslots/TimeslotSelect';
import useOperatorContext from '../../../../../contexts/operator/useOperatorContext';
import MenuItem from "../items/MenuItem";
import {Preferences} from "../../MenuPage";
import mixpanel from "mixpanel-browser";
import {trackEvent} from "../../../../../services/api/operator";
import {getDeliveryFee} from "../../../../../services/api/basket";
import PageSpinnerThree from "../../../../general/PageSpinnerThree";
import CartButton from "./CartButton";

type OrderModalProps = {
    onHide: () => void;
    show: boolean;
    reset: boolean;
    items: Item[];
    showItemModal: (item: Item, edit: boolean) => void;
    preferences: Preferences
};

const OrderModal = ({ show, onHide, reset = false, items, showItemModal, preferences }: OrderModalProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();

    const [navHorizontalScroll, setNavHorizontalScroll] = useState(0);
    const [navHorizontalScrollMax, setNavHorizontalScrollMax] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const bodyContent = useRef<HTMLDivElement>(null);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [fetchingDeliveryFee, setFetchingDeliveryFee] = useState(false);

    const [shadowTop, setShadowTop] = useState(false);
    const [shadowBottom, setShadowBottom] = useState(false);
    const [showTimeslotHelper, setShowTimeslotHelper] = useState(false);
    const [showItemTimeRestrictionError, setShowItemTimeRestrictionError] = useState(false);
    const [timeslotError, setTimeslotError] = useState<string|null>(null);

    useEffect(() => {
        let el = document.querySelector('.upsell-items');

        function handleResize() {
            if ( el ) {
                setNavHorizontalScrollMax( el.scrollWidth - el.clientWidth);
            }
        }
        // Add event listener
        window.addEventListener("resize", handleResize);

        // Fire once on load
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [show, ref.current]);

    useEffect(() => {

        let el = document.querySelector('.upsell-items');

        const updateElementPosition = () => {
            let val = el?.scrollLeft ?? 0;
            setNavHorizontalScroll(val);
        }

        el?.addEventListener("scroll", updateElementPosition);
        updateElementPosition();

        return () => el?.removeEventListener("scroll", updateElementPosition);

    }, [show, bodyContent.current]);

    const scroll = (scrollOffset: number) => {
        let element = document.querySelector('.upsell-items') as HTMLElement | null;
        let offset = navHorizontalScroll;
        if ( element ) {
            element.scrollTo({ left: offset + scrollOffset, behavior: 'smooth' });
        }

        // if (ref.current) {
        //     ref.current.scrollTo({left: ref.current.scrollLeft += scrollOffset, behavior: 'smooth'});
        // }
    };


    useEffect(() => {
        if (reset) {
            basketContext.clearBasket();
        }
    }, [])

    useEffect(() => {
        if (items.length === 0) {
            onHide();
        }
    }, [items])

    useEffect(() => {

        function updateScrollPosition() {
            if (bodyContent && bodyContent.current ) {

                if(bodyContent?.current?.scrollTop > 78) {
                    setShadowTop(true)
                } else {
                    setShadowTop(false)
                }
                if (bodyContent?.current?.scrollHeight - bodyContent?.current?.scrollTop === bodyContent?.current?.clientHeight) {
                    setShadowBottom(false)
                } else {
                    setShadowBottom(true)
                }
            }
        }

        updateScrollPosition();

        if (bodyContent && bodyContent.current) {
            bodyContent.current.addEventListener("scroll", updateScrollPosition, false);

            document.getElementById('order-modal')?.classList.add('anim-enter');

            if ( show ) {
                mixpanel.track('show order modal');
            }

            return () => {
                setShadowTop(false);
                setShadowBottom(false);
                bodyContent?.current?.removeEventListener("scroll", updateScrollPosition, false);
                document.getElementById('order-modal')?.classList.remove('anim-enter');
            };
        }
    }, [bodyContent.current, show]);

    useEffect(() => {
        if ( show && siteContext.channel === 'dine_in' ) {
            siteContext.fetchTimeslots(
                siteContext.site?.uid ?? basketContext.validatedSite?.uid ?? '',
                siteContext.flow);
        }
        if ( show ) {
            trackEvent('open-order', siteContext.site?.uid ?? '');
        }

        if ( show && ['delivery', 'catering'].includes(siteContext.channel) ) {
            updateDeliveryFee();
        }

    }, [ show ]);

    useEffect(() => {

        if ( siteContext.channel === 'dine_in' && siteContext.timeslots ) {
            basketContext.setTimeslot(siteContext.timeslots[0]);
        }

    }, [ siteContext.timeslots ]);

    useEffect(() => {
        if ( ['delivery', 'catering'].includes(siteContext.channel) && siteContext.timeslots ) {

            // if the user has selected a slot
            // and the slot chosen is the first one of the list
            // we don't run the update because the price already
            // reflects the first slot..

            if ( basketContext.timeslot && basketContext.timeslot.id === siteContext.timeslots[0]['id'] ) {
                return;
            }

            updateDeliveryFee(basketContext.timeslot?.id);
        }

    }, [ basketContext.timeslot?.id, basketContext.basketItems ])


    const updateDeliveryFee = async (slot: string = '') => {
        if ( !siteContext || !siteContext.site || !basketContext || !show) {
            return;
        }

        setFetchingDeliveryFee(true);
        setTimeslotError(null);

        try {
            await getDeliveryFee(
                items,
                siteContext.site.uid,
                localStorage.getItem('address') ?? '',
                siteContext.flow,
                siteContext.channel,
                basketContext.timeslot?.id ?? '',
                basketContext.timeslot?.is_asap ?? false
            )
                .then( (data) => {
                    setDeliveryFee(data.delivery_fee);
                    setFetchingDeliveryFee(false);

                    if ( data.timeslots ) {
                        siteContext.setTimeslots(data.timeslots);
                    }

                });

        } catch (error: any) {
            if (error.code && [847, 848, 849, 862].includes(error.code)) {
                setTimeslotError(error.message);
                if ( error.extra_data.length > 0 ) {
                    siteContext.setTimeslots(error.extra_data);
                }
            }
            setFetchingDeliveryFee(false);
        }


    };



    return (
        <StyledModal
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            backdropClassName="modal-order-backdrop"
            className="order-modal-dialog"
            centered
            id={'order-modal'}
            animation={false}
        >
            <Modal.Header>
                <h4 className='modal__title modal-title-os'>{t`Your order`}</h4>
                <div className="modal-close__wrapper">
                    <button className={'modal-close__btn'} role="button" onClick={() => onHide()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body ref={bodyContent} className={`order-info ${shadowTop ? 'modal-shadow-body' : ''}`}>

                { /*operatorContext.operator?.allergen_info && (
                    <AllergenInfo className="modal-block">
                        <div className="allergen-block">
                            <div className="allergen-title">
                                <img src={info} alt="Icon" className="svg-18" />
                                Allergen advice
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: operatorContext.operator.allergen_info }} />
                        </div>
                    </AllergenInfo>
                ) */}

                <BasketItemsWrapper>
                    {items.map((item, index) => (
                        <OrderItem
                            item={item}
                            index={index}
                            key={`basket-item-${index}-${item.uid}`}
                            showItemModal={ () => { showItemModal(item, true) }}
                        />
                    ))}
                </BasketItemsWrapper>

                {siteContext.menu?.categories?.filter((cat) => cat.name.toLowerCase() === 'upsell' && !cat.is_hidden).map((cat) => {
                    if ( cat.items.filter((i) => !basketContext.basketItems.map((bi) => bi.uid).includes(i.uid))
                        .filter((i) => !i.is_hidden).length > 0 ) {
                        return (
                            <UpsellItemsWrapper key={'upsell-wrapper'}>
                                <div key={`upsell-cat-${cat.uid}`}>
                                    <div className={'upsell-header'}>
                                        <p className={'upsell-title'}>People also added</p>
                                        <div className={'upsell-nav'}>
                                            <div className={`chevron__wrapper ${(navHorizontalScrollMax - navHorizontalScroll < 48) ? 'reached-end' : ''}`} onClick={() => {
                                                mixpanel.track('Upsell scroll back');
                                                scroll(-300);
                                            }}>

                                                <div className="chevron chev-left">
                                                    <i className="feather feather-chevron-left feather-18 brand-color-filter"/>
                                                </div>

                                            </div>

                                            <div className={`chevron__wrapper ${(navHorizontalScrollMax - navHorizontalScroll < 48) ? 'reached-end' : ''}`} onClick={() => {
                                                mixpanel.track('Upsell scroll fwd');
                                                scroll(300)
                                            }}>
                                                <div className="chevron chev-right">
                                                    <i className="feather feather-chevron-right feather-18 brand-color-filter"/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={`upsell-items ${navHorizontalScroll > 0 ? ' scrolled' : ''}`} ref={ref}>

                                        { cat.items.filter((i) => !basketContext.basketItems.map((bi) => bi.uid).includes(i.uid))
                                            .filter((i) => !i.is_hidden)
                                            .map((item, index) => (
                                                <MenuItem
                                                    item={item}
                                                    key={`upsell-${index}-${item.uid}`}
                                                    showItemModal={showItemModal}
                                                    allowItemImages={operatorContext.operator?.allow_menu_item_images ?? false}
                                                    preferences={preferences}
                                                    variant="upsell"
                                                />
                                            )) }

                                    </div>
                                </div>
                            </UpsellItemsWrapper>
                        )
                    }
                })}

                { ['delivery', 'catering'].includes(siteContext.channel) && (
                    <LineItem className="modal-block">
                        <div className="basket__subtotal">
                            <span className="d-block w-100">{t`Delivery Fee`}</span>
                            <span style={{ position: 'relative' }} className={ fetchingDeliveryFee ? 'is-loading' : '' }>
                                <PageSpinnerThree
                                    show={fetchingDeliveryFee}
                                    text={ deliveryFee === 0 ? 'Free' : money(deliveryFee, operatorContext.operator?.ccy, operatorContext.operator?.locale) }
                                    color={operatorContext.operator?.theme.v3.ui.other.link ?? '#000000'}
                                />
                            </span>

                        </div>
                    </LineItem>
                )}

                <LineItem className="modal-block">

                    <div className="basket__subtotal">
                        <span className="d-block w-100">{t`Subtotal`}</span>
                        <span style={{ position: 'relative' }} className={ fetchingDeliveryFee ? 'is-loading' : '' }>
                            <PageSpinnerThree
                                show={fetchingDeliveryFee}
                                text={money(basketContext.price.subtotal + deliveryFee, operatorContext.operator?.ccy, operatorContext.operator?.locale)}
                                color={operatorContext.operator?.theme.v3.ui.other.link ?? '#000000'}
                            />
                        </span>
                    </div>


                    {/* <div className="basket__tip"> */}
                    {/* <TipAddBasket id={'tipBasketID'} tipValue={money(basketContext.price.subtotal * 0.1)} disabled={true}/> */}
                    {/* TODO: Figure out what the service should be here? */}
                    {/* </div> */}
                </LineItem>

                {siteContext.channel !== 'dine_in' && operatorContext.operator?.allow_special_notes && (
                    <SpecialInstructions className="modal-block">
                        <span className="d-block">{t`Special instructions`}</span>
                        <OrderNote placeholder="Add a note"
                           maxLength={100}
                           className={'form-control--textarea'}
                           onChange={(e) => basketContext.setSpecialNotes(e.target.value) }
                        />
                    </SpecialInstructions>
                )}

                {/* <Banner className='d-none d-sm-flex'>
                    <span>Spend another £7.00 for free delivery</span>
                    <span>View Menu</span>
                </Banner> */}
                {/* {renderTotal()} */}


            </Modal.Body>
            <Modal.Footer className={'modal-show-footer-shadow'}>
                <StyledForm>
                    <Form.Group controlId="basketForm" className="mb-0">
                        { siteContext.channel !== 'dine_in' && (<StickyElement>
                            <TimeslotSelect
                                timeslots={siteContext.timeslots}
                                selectedTimeslotId={basketContext.timeslot?.id}
                                disabledSelect={fetchingDeliveryFee}
                            />

                            { showTimeslotHelper && !basketContext.timeslot && (
                                <ErrorContainer>
                                    <i className="feather feather-info feather-20 color-filter-invalid" />
                                    <span>Please select a collection time.</span>
                                </ErrorContainer>
                            )}
                            { timeslotError && (
                                <ErrorContainer>
                                    <i className="feather feather-info feather-20 color-filter-invalid" />
                                    <span>{timeslotError}</span>
                                </ErrorContainer>
                            )}
                            { showItemTimeRestrictionError && (
                                <ErrorContainer>
                                    <i className="feather feather-alert-circle feather-20 color-filter-invalid" />
                                    <span>Some items are unavailable at this collection time</span>
                                </ErrorContainer>
                            )}

                        </StickyElement>)}

                        { items.length && siteContext.isTabs ? (

                            <CartButton
                                classes={'mobile-cart-btn'}
                                CTAProps={{ dual: false }}
                                onClick={async () => {
                                    basketContext.addToTab();
                                }}
                                label={'Place order'}
                                type={'bill'}
                            />

                        ) : items.length ? (
                            <CheckoutButton
                                showTimeslotHelper={setShowTimeslotHelper}
                                setTimeslotError={setTimeslotError}
                                label={'Place order'}
                            />) : null}

                    </Form.Group>
                </StyledForm>
            </Modal.Footer>
        </StyledModal>
    );
};

const ErrorContainer = styled.div`
    color: var(--invalid);
    padding-top: 8px;
    display: flex;
    align-items: center;
    & i { margin-right: 8px; }
    & span { line-height: 20px; }
`;

const StickyElement = styled.div`

    margin-bottom: 16px;

    // @media screen and (min-width: 768px) {
    //     top: -41px;
    // }
    //
    // z-index: 2;
    // position: sticky;
    // top: 0 !important;
    // background: white;




`;

const Banner = styled.div`
    display: flex;
    justify-content: space-between;
    background: rgba(0, 205, 0, 0.5);
    padding: 10px 24px;
    font-size: 14px;
    font-weight: 600;
    margin: 48px -24px 0;
    @media screen and (max-width: 575px) {
        margin: 24px -16px;
    }
`;

const OrderNote = styled.textarea`
    border: 1px solid var(--border-grey);
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    height: 84px;
    resize: none;
    margin-top: 8px;

    &::placeholder {
        color: var(--text-grey);
        font-size: 16px;
    }
`;

const UpsellItemsWrapper = styled.div`
    padding-top: 12px;
    position: relative;
    // background-color: var(--layouts_body_menu_bg);


    border-bottom: 1px solid var(--border-grey);

    .upsell-header {
        display: flex;
        padding-left: 20px;
        padding-right: 16px;
        gap: 12px;
        .upsell-title {
            color: var(--text-black);
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            flex-grow: 1;
        }
        .upsell-nav {
            display: flex;
            gap: 4px;
            height: 22px;
            .chevron__wrapper {
                width: 40px; height: 40px;
                margin-top: -9px;
                background-color: white;
                border-radius: 50%;
                 &.reached-end {
                    /* opacity: 0; */
                    .chevron {
                        color: var(--text-grey);
                    }
                }
            }


            .chevron {
                width: 40px; height: 40px;
                background-color: white;
                position: relative;
                display: flex;
                align-items: center;
                border-radius: 50%;
                justify-content: center;
                cursor: pointer;
                color: var(--brand);
                transition: background-color 0.15s ease-in-out;
                &:hover {
                    background-color: var(--brand-10);
                }

            }
        }
    }

    .upsell-items {
        display: flex;
        gap: 20px;
        flex-direction: row;
        padding: 8px 20px 20px 20px;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

        &.scrolled .chev-wrap-left {
            /* opacity: 1; */
            display: inline-block;
        }

    }

    .chevron-wrap {

        height: 96px;
        z-index: 1;
        display: flex;
        padding: 0 20px;

        &:hover { cursor: pointer; }



        .modal-close__wrapper {
            position: absolute;
            right: 1rem;
            height: 40px;
            width: 40px !important;
            background-color: var(--white);
            border-radius: 50%;
            .modal-close__btn {
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                line-height: 0;
                transition: background-color 0.15s ease-in-out;
                :hover {
                    background-color: var(--brand-10);
                }
            }
        }

    }

`;

const LineItem = styled.div`
    .form-group,
    .form-label {
        margin: 0;
    }
    .basket__subtotal {
        display: flex;
        align-items: center;

        > span {
            color: var(--text-black);
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            &.is-loading {
                position: relative;
                min-width: 65px;
                right: -10px;
            }
        }
    }
    .basket__tip {
        padding: 8px 0;
        display: block;
    }
`;

const BasketItemsWrapper = styled.div`
`;

const StyledForm = styled(Form)`
    .form-group {
        margin-bottom: 16px;
    }
    width: 100%;
`;

const SpecialInstructions = styled.div`
    span {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
    }
`;

const StyledModal = styled(Modal)`


    .modal-block {
        padding: 20px;
        &:not(:last-child){
            border-bottom: 1px solid var(--border-grey);
        }
    }

    .close {
        left: 16px;
        right: auto;
        opacity: 1 !important;
    }
    .modal-body {
        overflow-y: auto;

        &.modal-shadow-body > div:first-child {
            border-bottom: none !important;
            box-shadow: 0px 0px 6px rgb(0 0 0 / 16%);
            transition: box-shadow 0.15s ease-in-out;
        }
    }
    .modal-header {
        border-bottom: 1px solid var(--border-grey) !important;
        transition: box-shadow 0.15s ease-in-out;
        display: flex;
        min-height: 64px;
        align-items: center;
        padding: 16px 20px;
        > div { width: 100%}
        .modal__title {
            margin-bottom: 0;

            // CUSTOM PP - done
            //-webkit-text-stroke-width: 1px;
            //-webkit-text-stroke-color: #00572e;
            //text-shadow: 1px 1px 0 #00572e, 2px 2px 0 #00572e, 3px 3px 0 #00572e, 4px 4px 0 #00572e, 5px 5px 0 #00572e, 6px 6px 0 #00572e, 7px 7px 0 #00572e, 8px 8px 0 #00572e;
        }
        &.modal-shadow-top {
            /*box-shadow: 0 4px 20px rgba(0,0,0,0.12);*/
        }

    }

    .modal-footer {
        transition: box-shadow 0.15s ease-in-out;
        padding: 16px 20px !important;
    }

    .modal-body {
        .order-info {
            font-size: 14px;
        }

        padding: 0 !important;

        table {
            table-layout: fixed;
            margin: 1.5rem 0;
            tr td {
                line-height: 30px;
                padding: 0;
                border: 0;
                font-weight: 600;
                &:last-child {
                    font-weight: 400;
                    color: var(--text-black);
                }
            }
        }
    }



`;

export default OrderModal;
