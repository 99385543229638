import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOperator } from '../../services/api/operator';
import { OperatorContext, OperatorContextData, OperatorData } from './operator-context';
import {iLog} from "../../index";
import mixpanel from "mixpanel-browser";


const OperatorContextProvider = ({ children }: { children: JSX.Element }) => {
    const [operator, setOperator] = useState<OperatorData | null>(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const fetchOperator = (handle: string) => {
        setLoading(true);
        
        getOperator(handle)
            .then((o: OperatorData) => {
                iLog('Operator fetched from api... ', o);
                setOperator(o);
                iLog('operator', operator);
                mixpanel.register({
                    'env': process.env.REACT_APP_ENV,
                    'operator': o.name
                });
            })
            .catch((error) => {
                console.error('Operator fetch error:', error);
                navigate('/404');
            })
            .finally(() => {
                console.log('finally');
                setLoading(false)
            });
    };

    let operatorContextValue: OperatorContextData = {
        operator,
        setOperator,

        loading,
        setLoading,

        fetchOperator,
    };
    return <OperatorContext.Provider value={operatorContextValue}>{children}</OperatorContext.Provider>;
};

export default OperatorContextProvider;
