import styled from 'styled-components';
import toaster from 'toasted-notes';
import Alert from '../components/general/Alert';
import mixpanel from "mixpanel-browser";

interface NotificationProps {
    msg: string;
    onClose: () => void;
}

const CustomNormalNotification = ({ msg, onClose }: NotificationProps) => {

    mixpanel.track('alert shown', {
        'message': msg
    });

    return (
        <StyledAlert onClose={onClose} dismissible>
            <>{msg}</>
        </StyledAlert>
    );
};

export const toastNormal = (msg: string, duration?: number) => {
    toaster.notify(({ onClose }) => <CustomNormalNotification msg={msg} onClose={onClose} />, {
        duration: 5000,
        position: 'top'
    });
};

const StyledAlert = styled(Alert)`
    text-align: left;
    position: relative;
    padding: 12px 36px 12px 20px;
    //background-color: var(--ui_buttons_cta_bg);
    background-color: #fb80b7;
    color: #FFFFFF;
    border-radius: 6px;
    font-family: 'ChelseaRR', sans-serif;
    > div {
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
    }
    > .close {
        padding: 6px 8px;
        opacity: 1;
        font-weight: 500;
        right: 4px;
        &:hover {
            color: var(--border-grey);
        }
    }
`;
