import {t} from "ttag";
import {getDisplayName} from "../../../../services/channel";
import React from "react";
import useOrderContext from "../../../../contexts/order/useOrderContext";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";
import styled from "styled-components";


const DefaultChannel = () => {

    const orderContext = useOrderContext();
    const operatorContext = useOperatorContext();

    if (!orderContext || !orderContext.order || orderContext.loading ) {
        return null;
    }

    console.log('order', orderContext.order);

    const getFirstName = () => {
        return orderContext.order?.customer.first_name;
    }

    let displayName = getDisplayName(orderContext.order?.channel);

    if ( orderContext.order.channel === 'catering' ) {
        displayName = operatorContext.operator?.theme.v3.language.catering;
    }

    return (
        <>
            <h1 className={'conf__thanks'}>{t`Thank you`}{getFirstName() ? (<>,<br/>{getFirstName()}!</>) : null}</h1>
            <StyledCard border>
                <StyledBlock>
                    {/*<h2>{t`Thank you`}, {orderContext.order.customer_name}</h2>*/}
                    <div>
                        {/*<span>{t`Your Order`}: {orderContext.order.ref}</span>*/}
                        <h4 className={'conf__heading'}>{t`Order`} #{orderContext.order.ref.substring(9)}</h4>
                    </div>

                    <div className={'conf__info'}>
                        <span className="conf__info__channel">
                            { orderContext.order?.channel === 'dine_in' ? 'Table ' + orderContext.order?.table : displayName }
                        </span>
                        <span className="conf__info__site_name">{operatorContext.operator?.name + ', ' + orderContext.order?.site?.name}</span>
                        <span className="conf__info__timeslot">{orderContext.order.timeslot}</span>
                    </div>

                    {/*<EmailSignup />*/}
                </StyledBlock>
                {/*{siteContext.site?.show_instructions_at_confirm && siteContext.site?.instructions ? (*/}
                {/*    <Instructions>*/}
                {/*        <i className="feather feather-info" />*/}
                {/*        <span>{ siteContext.site?.instructions }</span>*/}
                {/*    </Instructions>*/}
                {/*) : null}*/}


            </StyledCard>
            {orderContext.order.site?.show_instructions_at_confirm && orderContext.order.site?.instructions ? (
                <StyledCard>
                    <StyledBlock bg_var={"brand-10"} className={'conf__note'}>
                        <div className={'conf__note__header'}><i className="feather feather-info" /><span>{t`Restaurant note`}</span></div>
                        <span>{ orderContext.order.site?.instructions }</span>
                    </StyledBlock>
                </StyledCard>
            ) : null}
        </>
    );

}

const StyledCard = styled.div<{mobileBorderTop?: boolean, border?: boolean}>`  
    ${(props) => props.mobileBorderTop ? 'border-top: 1px solid var(--border-grey);' : ''}
    @media screen and (min-width: 993px){
        ${(props) => props.border ? 'border: 1px solid var(--border-grey);' : ''}
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 32px;
    }
`;

const StyledBlock = styled.div<{bg_var?: string, borderTop?: boolean, noMobilePadding?: boolean}>`
    width: 100%;
    ${(props) => props.noMobilePadding ? null : "padding: 20px;"}
    background-color: var(--${(props) => props.bg_var ?? 'white'});
    ${(props) => props.borderTop ? 'border-top: 1px solid var(--border-grey);' : null}
    @media screen and (min-width: 993px) {
        padding: 20px;
    }
`;

export default DefaultChannel;