import React, {useContext, useEffect, useRef, useState} from 'react';
import { Nav } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import styled, {ThemeContext} from 'styled-components';
import { Category } from '../../../../contexts/site/site-context';
import useScrollPositionOnElement from '../../../../hooks/useScrollPositionOnElement';
import { TSize } from '../../../general/NavBadge';

type MenuCategorySelectorProps = {
    categories?: Category[];
    bannerOffset?: Boolean
};

const MenuCategorySelector = ({ categories, bannerOffset }: MenuCategorySelectorProps) => {

    const themeContext = useContext(ThemeContext);
    const navHorizontalScroll = useScrollPositionOnElement('.scrollSpyScroll');
    //const [navHorizontalScrollMax, setNavHorizontalScrollMax] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const [scrolling, setScrolling] = useState(false);

    const scrollToActive = () => {

        let el = document.querySelector('.myScroll div.active') as HTMLElement|null;
        let parent = document.querySelector('.scrollSpyScroll') as HTMLElement|null;
        let parentWrapper = document.querySelector('.myScroll') as HTMLElement|null;

        let ourOffset = 30;

        if ( el && parent && parentWrapper && !scrolling) {

            const elLeft = el.offsetLeft + el.offsetWidth;
            const elParentLeft = parent.offsetLeft + parent.offsetWidth;

            // check if element not in view
            if (elLeft >= elParentLeft + parent.scrollLeft) {
                parent.scrollLeft = elLeft - elParentLeft + ourOffset;
            } else if (elLeft <= parent.offsetLeft + parent.scrollLeft) {
                parent.scrollLeft = el.offsetLeft - parent.offsetLeft;
            }

            if (el === parentWrapper.firstElementChild) {
                parent.scrollLeft = 0;
            } else if (el === parentWrapper.lastElementChild) {
                parent.scrollLeft = 10000;
            }

        }

    };

    useEffect(() => {
        //let el = document.querySelector('.scrollSpyScroll');

        function handleResize() {
            // if ( el ) {
            //     setNavHorizontalScrollMax( el.scrollWidth - el.clientWidth);
            // }
        }
        // Add event listener
        window.addEventListener("resize", handleResize);

        // Fire once on load
        handleResize();


        let scrollTimeout: string | number | NodeJS.Timeout | undefined;
        window.addEventListener('scroll', function(e) {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(function() {
                scrollToActive();
            }, 100);
        });

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const scrollHeight = () => {
        return window.screen.width >= 768 ? 16 : 70;
    };

    const handleLinkClick = (index: number, uid: string) => {

        setScrolling(true);

        if ( index === 0 ) {
            window.scrollTo({top: scrollHeight(), behavior: 'smooth'})
            return;
        }

        document.querySelector(`#${uid}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
            setScrolling(false);

        }, 1000);

        // if ( (index + 1) === categories?.filter((cat) => cat.name !== 'upsell').length ) {
        //     const navs = document.querySelectorAll('.myScroll div');
        //
        //     navs?.forEach(nav => {
        //         nav.classList.remove('active');
        //     });
        //
        //     document.querySelector('.myScroll div:last-child')?.classList.add('active');
        // }
    };

    const links = () => categories?.filter((cat) => cat.name.toLowerCase() !== 'upsell').map((category, index) => {
        if (category.is_hidden) {
            return null;
        }

        return (
            <StyledBadge
                size={'md'}
                key={`${category.uid}-nav`}
                id={`${category.uid}-nav`}
                variant={themeContext.v3.ux.menu_nav}
                className={'styled-badge'}
            >
                <button onClick={() => handleLinkClick(index, category.uid)} >
                    <h3 className="text-ui-font">
                        <span>{category.name}</span>
                    </h3>
                </button>
            </StyledBadge>
        );
    });

    if (!categories) {
        return null;
    }

    /*const scroll = (scrollOffset: number) => {
        if (ref.current) {
            ref.current.scrollTo({left: ref.current.scrollLeft += scrollOffset, behavior: 'smooth'});
        }
    };*/



    return (
        <>
            <Wrapper>
                <StyledNav
                    className={`d-block${navHorizontalScroll > 0 ? ' scrolled' : ''}`}
                >
                    {/* <NavPrev href="javascript: void(0)"
                        className="nav-arrow nav-arrow-prev"
                        onClick={() => scroll(-250)}>
                        <i className='bx bx-chevron-left'/>
                    </NavPrev> */}
                    <ScrollspyWrapper className="scrollSpyScroll" ref={ref}>
                        <Scrollspy
                            offset={bannerOffset ? (-80-98) : -80}
                            componentTag={'div'}
                            items={categories?.filter((cat) => cat.name.toLowerCase() !== 'upsell').map((category) => `${category.uid}`)}
                            currentClassName="active"
                            style={ {alignItems: 'center'} }
                            className={`myScroll`}
                            rootEl={'.scrollSpyScroll'}
                            onUpdate={ () => { scrollToActive() } }
                        >
                            {links()}
                        </Scrollspy>
                    </ScrollspyWrapper>

                    {/* todo: why does it not stay put (pos:abs, r:0) when screen is > 1140px?! */}

                    {/* <NavNext
                        href="javascript: void(0)"
                        onClick={() => scroll(250)}
                        className="nav-arrow nav-arrow-next"
                        style={{ opacity: (navHorizontalScrollMax - navHorizontalScroll < 48) ? '0' : '1' }}
                    >
                        <i className='bx bx-chevron-right'/>
                    </NavNext> */}
                </StyledNav>
                <Shadow/>
            </Wrapper>
        </>
    );
};



const Shadow = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    bottom:0;
    width: 32px;
    background: linear-gradient(90deg, var(--ui_nav_menu_bg_opa0), var(--ui_nav_menu_bg));
    z-index: 2;
`;

const Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
`;

const NavNext = styled.a`
    right: 0;
    top: 0;
    padding-right: 8px;
    margin-right: 20px;
    box-shadow: -4px 0px 8px 8px white;
    i { transition: scale 0.4s ease-in-out, box-shadow 0.2s ease-in-out; }
    &:hover {
        i {
            transform: scale(1.05);
            box-shadow: 0 2px 8px rgba(0,0,0,0.08);
        }
    }

    /* new idea.. */
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    transition: box-shadow .2s ease-in-out, background-color 0.2s ease-in-out;
    padding-right: 0;
    border-radius: 50%;
    width: 30px;
`;

const NavPrev = styled.a`
    left: 0;
    margin-left: 20px;
    box-shadow: 4px 0px 8px 8px white;
    padding-left: 8px;
    display: none;
    i { transition: scale 0.4s ease-in-out, box-shadow 0.2s ease-in-out; }
    &:hover {
        i {
            transform: scale(1.05);
            box-shadow: 0 2px 8px rgba(0,0,0,0.08);
        }
    }
`;

const StyledNav = styled(Nav)`
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;
    z-index: 2;
    position: relative;


    @media screen and (min-width: 768px) {
        .myScroll {
            gap: 10px;
        }
    }

    & > div {

    }

    .nav-arrow {
        width: 38px;
        height: 30px;
        position: absolute;
        color: var(--ui_nav_menu_color);
        background-color: white;
        top: 50%;
        transform: translateY(-50%);

        /* new idea.. */
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
        transition: box-shadow .2s ease-in-out, background-color 0.2s ease-in-out;
        padding: 0;
        border-radius: 50%;
        width: 30px;
        display: none;

        & i {
            font-size: 24px;
            line-height: 30px;
            width: 30px;
            text-align: center;
            background-color: white;
            /*border: 1px solid #E8E8E8;*/
            border-radius: 50%;
        }
    }



    @media screen and (min-width: 768px) {
        .nav-arrow {
            display: block;
        }
        &.scrolled .nav-arrow-prev {
            display: block;
        }
    }
`;

const ScrollspyWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 0 48px;
    @media screen and (max-width: 767px) { padding: 0 20px; }

    ::-webkit-scrollbar {
        display: none;
    }
    /* @media screen and (min-width: 1140px) {
        overflow-x: initial;
    } */
    > div {
        display: flex;
    }
`;
const StyledBadge = styled(Nav.Item)<{ size: TSize, variant: string }>`
    margin: 0 24px;
    &:first-child { margin-left: 0; }

    display: flex;

    // border-bottom: 2px solid transparent;

    button {
        border:none;
        background-color: transparent;
        
        cursor: pointer;
        text-decoration: none;
        display: flex;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
        align-items: center;

        h3 { 
            display: inline; 
            margin: 0; 
            padding: 0;
            font-size: var(--text_ui_menu_nav); 
            font-family: var(--text_ui_font);
            letter-spacing: var(--text_ui_spacing);
            color: var(--ui_nav_menu_color);
            text-transform: var(--text_ui_case);
        }

        .count {
            font-weight: 400;
            font-family: ${(props) => props.theme.bodyFont.name};
            font-size: 12px;
            margin-left: 4px;
        }
    }

    ${(props) => (props.variant === 'pills' && `
        
        margin: 10px 0;
        border-radius: 40px;
        text-overflow: ellipsis;
        margin-right: 6px;
        button {
            border: 1px solid transparent;
            border-radius: 40px;
            padding: 6px 16px;
            text-overflow: ellipsis;
            color: var(--ui_nav_menu_color);
            transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
            &:hover {
                // background-color: var(--ui_nav_channels_light);
                border-color: var(--brand);
                background-color: var(--brand-10);
            }
           
        }
        &.active {
            button {
                background-color: var(--brand);
                h3 { color: #FFFFFF !important; }
                // h3 { color: var(--ui_nav_menu_color) !important; }
                &:hover {
                    // background-color: var(--brand-10);
                    background-color: var(--brand);
                }
            }
        }
            
        &:focus {
            outline: none;
            box-shadow: 0px 0px 0px 4px var(--brand-opaque-15);
        }
        @media screen and (max-width: 767px) {
            margin: 10px 16px;
            &:first-child {
                margin-left: 0;
            }
        }
        `)}

    ${(props) => (props.variant === 'lines' && `
        transition: border-color 0.2s ease-in-out;
        position: relative;
        height: 56px;
        margin: 0 16px;
        
        button {
            padding: 0;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: transparent;
            transition: background-color 0.2s ease-in-out;
            pointer-events: none;
        }
        &:not(.active):hover {
            &:after {
                background-color: var(--border-grey);
            }
        }

        &.active {
            margin-bottom: 0;
            // border-color: var(--brand);
            &:after {
                background-color:  var(--ui_nav_menu_color);
            }
        }
        a {
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
        }

        @media screen and (max-width: 767px) {
            margin: 0 16px;
        }
    `)}


    // custom PP - done
    //background-color: transparent;
    //
    //align-items: center;
    //column-gap: 6px;
    //
    //position: relative;
    //
    //display: inline-flex;
    //flex-direction: row;
    //justify-content: center;
    //padding: 12px 14px 7px 20px;
    //color: #0c0 !important;
    //transition: transform .3s cubic-bezier(.215,.61,.355,1);
    //z-index: 3;
    //border-color: transparent;
    //
    //height: auto;
    //line-height: 1;
    //
    //&:hover {
    //    transform: translate3d(-6px, -6px, 0);
    //    cursor: pointer;
    //
    //    &::after {
    //        transform: translate3d(10px, 10px, 0);
    //    }
    //}
    //
    //&::before {
    //    background: #fff !important;
    //    border-color: #0c0 !important;
    //
    //    border: 2px solid #00572e;
    //    border-radius: 50px;
    //    content: "";
    //    height: 100%;
    //    left: 0;
    //    position: absolute;
    //    top: 0;
    //    width: 100%;
    //    z-index: 2;
    //}
    //
    //&::after {
    //    background: #0c0 !important;
    //    color: #0c0 !important;
    //
    //    background: #00572e;
    //    border-radius: 50px;
    //    content: "";
    //    height: calc(100% + 0px);
    //    left: -2px;
    //    position: absolute;
    //    top: -2px;
    //    transform: translate3d(4px, 4px, 0);
    //    transition: transform .3s cubic-bezier(.215,.61,.355,1), background .3s linear, color .3s linear;
    //    width: calc(100% + 0px);
    //    z-index: 1;
    //}
    //
    //& span {
    //    position: relative;
    //    z-index: 2;
    //    color: #0c0 !important;
    //    top: -1px;
    //    left: -2px;
    //}
    //
    //h3, span {
    //    line-height: 1;
    //}
    //
    //button {
    //    padding: 0;
    //}
    //
    //&.active {
    //    transform: translate3d(2px, 2px, 0);
    //    color: white !important;
    //
    //    span {
    //        color: white !important;
    //    }
    //}
    //&.active::before {
    //    background: #0c0 !important;
    //    border-color: #00572e !important;
    //}
    //&.active::after {
    //    background: #00572e !important;
    //    transform: translate3d(2px, 2px, 0);
    //    height: 100%;
    //    width: 100%;
    //}
    //
    //@media screen and (max-width: 767px) {
    //    margin: 10px 8px;
    //    &:first-child {
    //        margin-left: 0;
    //    }
    //}

    // ---

    
`;

export default MenuCategorySelector;