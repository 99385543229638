import React from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

type NavBadgeProps = {
    children: JSX.Element;
    size?: TSize;
    className?: string;
};

export type TSize = 'lg' | 'md';

const NavBadge = ({ children, size = 'md', className }: NavBadgeProps) => {
    return (
        <NavBadgeWrapper className={className} size={size}>
            {children}
        </NavBadgeWrapper>
    );
};

const NavBadgeWrapper = styled(Nav.Item)<{ size: TSize }>`
    margin: 0px;
    display: inline-block;

    &:first-child { margin-left: 0; }
    &:last-child .nav-link { margin-right: 0; }
    


    .nav-link {

        ${(props) => (props.size === 'md' ? 'padding: 11px 16px;' : null)}
        ${(props) => (props.size === 'lg' ? 'padding: 14px 24px;' : null)}

        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;

        font-family: var(--text_ui_font);
        font-weight: var(--text_ui_weight);
        letter-spacing: var(--text_ui_spacing);
        font-size: var(--text_ui_select_channel_nav);
        line-height: var(--text_ui_select_channel_nav);
        text-transform: var(--text_ui_case);
        
        color: var(--ui_nav_channels);
        
        

    }


    ${(props) => (props.theme.v3.ux.menu_nav === 'pills' && `
        .nav-link {
            height: 40px;
            line-height: 38px;
            padding: 0 14px;
            border-radius: 40px;
            text-overflow: ellipsis;
            margin-right: 8px;
            transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
            border: 1px solid var(--brand);
            &:hover {
                background-color: var(--brand-10);
            }
            
            // CUSTOM PP - not
            // &.active:not(.nav-link--solo) {
            //     // color: var(--ui_nav_menu_color);
            //     color: #FFFFFF;
            //     background-color: var(--brand);
            // }
            &--solo {
                pointer-events: none;
            }
            
            &:focus {
                outline: none;
                // box-shadow: 0px 0px 0px 4px var(--brand-opaque-15);
            }
            
        }
    `)}

    ${(props) => (props.theme.v3.ux.menu_nav === 'lines' && `
        margin-right: 24px;     
        .nav-link {
            border-bottom: 3px solid transparent;

            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 5px;
            

            line-height: 24px;      
            text-align: left;       

            transition: border-color 0.2s ease-in-out;

            &:not(.active):hover {
                border-color: var(--border-grey);
            }

            background-color: transparent;

            &.active {
                margin-bottom: 0;
                border-color: var(--ui_nav_channels);
                background-color: transparent;
                color: var(--ui_nav_channels);
            }

            @media screen and (max-width: 767px) {
               font-family: var(--text_ui_font);
                font-weight: var(--text_ui_weight);
                letter-spacing: var(--text_ui_spacing);
                font-size: var(--text_ui_select_channel_nav);
                line-height: var(--text_ui_select_channel_nav);
                text-transform: var(--text_ui_case);
            }

        //    full width

        max-width: none;
        width: 100%;
        
        }
    `)}
    
    .nav-link {
        // custom PP - done
        //background-color: transparent;
        //
        //align-items: center;
        //column-gap: 6px;
        //
        //position: relative;
        //
        //display: inline-flex;
        //flex-direction: row;
        //justify-content: center;
        //padding: 12px 20px 11px 24px;
        //color: #0c0 !important;
        //transition: transform .3s cubic-bezier(.215,.61,.355,1);
        //z-index: 3;
        //border-color: transparent;
        //
        //height: auto;
        //line-height: 1;
        //
        //&:hover {
        //    transform: translate3d(-6px, -6px, 0);
        //    cursor: pointer;
        //
        //    &::after {
        //        transform: translate3d(10px, 10px, 0);
        //    }
        //}
        //
        //&::before {
        //    background: #fff !important;
        //    border-color: #0c0 !important;
        //    
        //    border: 2px solid #00572e;
        //    border-radius: 50px;
        //    content: "";
        //    height: 100%;
        //    left: 0;
        //    position: absolute;
        //    top: 0;
        //    width: 100%;
        //    z-index: 2;
        //}
        //
        //&::after {
        //    background: #0c0 !important;
        //    color: #0c0 !important;
        //    
        //    background: #00572e;
        //    border-radius: 50px;
        //    content: "";
        //    height: calc(100% + 0px);
        //    left: -2px;
        //    position: absolute;
        //    top: -2px;
        //    transform: translate3d(4px, 4px, 0);
        //    transition: transform .3s cubic-bezier(.215,.61,.355,1), background .3s linear, color .3s linear;
        //    width: calc(100% + 0px);
        //    z-index: 1;
        //}
        //
        //& span {
        //    position: relative;
        //    z-index: 2;
        //    color: #0c0 !important;
        //}
        //
        //&.active {
        //    transform: translate3d(2px, 2px, 0);
        //    color: white !important;
        //
        //    span {
        //        color: white !important;
        //    }
        //}
        //&.active::before {
        //    background: #0c0 !important;
        //    border-color: #00572e !important;
        //}
        //&.active::after {
        //    background: #00572e !important;
        //    transform: translate3d(2px, 2px, 0);
        //    height: 100%;
        //    width: 100%;
        //}

        // ---
    }
    
    // buttons temporary swap for lines
    
    // ${(props) => (props.theme.v3.ux.menu_nav === 'lines' && `
    //     flex-grow: 1;
    //     .nav-link {
    //         max-width: none;
    //         height: 48px;
    //         text-align: center;
    //
    //         border-bottom: 0;
    //         color: var(--ui_nav_channels);
    //         border-radius: 40px;
    //         padding: 12px 20px;
    //
    //         font-size: var(--text_ui_select_channel_nav);
    //         line-height: 24px;            
    //         font-family: var(--text_ui_font);
    //         font-weight: var(--text_ui_weight);
    //         letter-spacing: var(--text_ui_spacing);
    //         text-transform: var(--text_ui_case);
    //
    //         transition: background-color 0.2s ease-in-out;
    //
    //         @media screen and (max-width: 767px) {
    //             font-size: 16px;
    //         }
    //
    //         &:not(.active):hover {
    //
    //         }
    //
    //
    //         &.active {
    //             margin-bottom: 0;
    //             background-color: var(--brand-10);
    //         }
    //
    //
    //
    //     }
    // `)}
`;

export default NavBadge;
